import http from '../utils/request'

const base = '/api/v1'

export function getComment(options) {
	return http(Object.assign({
		url: `${base}/comment`	
	}, options))
}

// 取名
export function createOrder(options) {
	return http(Object.assign({
		url: `${base}/order`,
		method: 'POST'
	}, options))
}

// 测分
export function testScore(options) {
	return http(Object.assign({
		url: `${base}/provider/test-score-detail`,
	}, options))
}

// 获取测分详情
export function getTestScoreDetail(options, id) {
	return http(Object.assign({
		url: `${base}/provider/test-score-detail-page/${id}`,
	}, options))
}

// 获取取名详情
export function getOrderDetail(id, options) {
	return http(Object.assign({
		url: `${base}/order/${id}`,
	}, options))
}

// 获取运势
export function getLuckyDetail(options) {
	return http(Object.assign({
		url: `${base}/provider/fortune-detail`,
	}, options))
}

// 获取音形义
export function getYinxingyi(options) {
	return http(Object.assign({
		url: `${base}/provider/sound-shape-detail`,
	}, options))
}

// 名如其人详情
export function getMingruqiren(options) {
	return http(Object.assign({
		url: `${base}/provider/celebrity-list`,
	}, options))
}

// 心理学起名详情
export function getXinlixue(options) {
	return http(Object.assign({
		url: `${base}/provider/psychology-detail`,
	}, options))
}

// 卦象详情
export function getZhouyi(options) {
	return http(Object.assign({
		url: `${base}/provider/hexagram-detail`,
	}, options))
}

// 微信jssdk配置
export function getWxConfig(options) {
	return http(Object.assign({
		url: `${base}/provider/jssdk-config`,
	}, options))
}

// 首页数据统计
export function getPeopleNum(options) {
	return http(Object.assign({
		url: `${base}/provider/home`,
	}, options))
}

// Pv统计
export function setPV(options) {
	return http(Object.assign({
		url: `${base}/provider/pv`,
	}, options))
}