<template>
	<div>
		<Card theme="center" :title="title + '评分'">
			<div class="geo-detail-more-type-card-header">
				<div class="geo-detail-more-type-card-header-tit">
					<div class="geo-detail-more-type-card-header-label">星座：</div>
					<div class="geo-detail-more-type-card-header-value">{{xingzuoData.name}}<span>（{{xingzuoData.english_name}}）</span></div>
				</div>
				<div class="geo-detail-more-type-card-header-chart">
					<CircleChart :title="$circleTitle(score)" :score="score" color="#EA7373" :radius="100" :barWidth="8"></CircleChart>
				</div>
			</div>
			<ContentCard :title="xingzuoData.name + '优点'">
				<van-tag class="geo-tag" v-for="item in xingzuoData.advantage.split('，')" :key="item">{{item}}</van-tag>
			</ContentCard>
			<ContentCard :title="xingzuoData.name + '缺点'">
				<van-tag class="geo-tag" v-for="item in xingzuoData.shortcoming.split('，')" :key="item">{{item}}</van-tag>
			</ContentCard>
			<div class="geo-text">
				星座评分解释：{{xingzuoData.text}}
			</div>
		</Card>
	</div>
</template>

<script>
import Card from '../Card'
import ContentCard from '../ContentCard'
import CircleChart from '../CircleChart'
export default {
	name: 'XingzuoCard',
	props: {
		title: String,
		data: Object,
		score: Number || String
	},
	components: {
		Card,
		ContentCard,
		CircleChart
	},
	computed: {
		scoreList() {
			return this.data.score_list
		},
		xingzuoData() {
			return this.data.analysis_list.xing_zuo
		},
		scoreData() {
			return this.data.free_names[this.$route.query.index].score_table
		}
	},
	data() {
		return {
			
		}
	}
}
</script>

<style lang="less" scoped>
	
</style>