<template>
	<v-chart :options="optionData" :init-options="initOptions"></v-chart>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'

export default {
	name: 'BarBase',
	components: {
		'v-chart': ECharts
	},
	props: {
		data: {
			type: Array,
			default: function() {
				return []
			}
		}
	},
	computed: {
		optionData() {
			this.options.xAxis.data = this.data.map(item => item.name)
			this.options.series[0].data = this.data.map(item => item.value)
			return this.options
		}
	},
	data() {
		return {
			initOptions: {
        renderer: 'canvas'
			},
			options: {
				grid: {
					x: 30,
					x2: 8,
					y: 8,
					y2: 60
				},
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: {
					type: 'value'
				},
				series: [{
					data: [],
					type: 'bar',
					itemStyle: {
						normal: {
							color: '#EA7373'
						}
					}
				}]
			}
		}
	}
}
</script>

<style lang="less">
.echarts {
	width: 100%;
	height: 100%;
}
</style>