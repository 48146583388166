<template>
	<div class="geo-zhdp-container">
		<Card theme="center">
			<div
				class="font-header-box flex"
				:class="[(index === mingArr.length - 1) && 'geo-margin-top']"
				v-for="(item, index) in mingArr"
				:key="item">
				<div class="geo-name-font-box">
					<div class="geo-name-font">{{item}}</div>
				</div>
				<div class="geo-font-detail-item">
					<div class="name">拼音</div>
					<div class="con">{{pinYin[index]}}</div>
				</div>
				<div class="geo-font-detail-item">
					<div class="name">繁体</div>
					<div class="con">{{fanTi[index]}}</div>
				</div>
				<div class="geo-font-detail-item">
					<div class="name">五行</div>
					<div class="con">{{wuXing[index]}}</div>
				</div>
			</div>
		</Card>
		<Card theme="center" title="姓名匹配">
			<div class="zhdp-charts-box flex">
				<div class="chart" :class="'chart' + index + 1" v-for="(item, index) in nameMatchs" :key="item.name">
					<div class="chart-tag">{{item.label}}</div>
					<div class="chart-name">{{item.name}}</div>
					<CircleChart v-if="item.score" :title="getTitle(item.score)" :score="item.score" color="#EA7373" :radius="80" :barWidth="10"></CircleChart>
				</div>
			</div>
		</Card>
		<Card theme="center" title="各项评分">
			<div class="zhdp-radar-box" v-if="radarData">
				<RadarChart :data="radarData" :indicator="radarIndicator"></RadarChart>
			</div>
			<ScoreCard :table="zhdpData && zhdpData.score_table"></ScoreCard>
		</Card>
		<!-- <Card theme="center" title="名字综合简评">
			<ContentCard :title="'字义起名'" :icon="'ziyi'">
				<template #right>
					<div class="zhdp-card-header-right">
						<span>去看详细分解</span>
						<van-icon name="arrow" />
					</div>
				</template>
				奎：指文苑、文人荟萃之地，如奎垣、狭小。用作人民意指博学、有文采之义；欧：1、一般指国家，海鸥，以及欧元；2、通“讴”，指歌唱。用作人名意指多才多艺、自由、财源广进之义。
			</ContentCard>
			<div class="geo-line geo-margin-top geo-margin-bottom"></div>
			<ContentCard :title="'音律字型'" :icon="'yinxingyi'">
				<template #right>
					<div class="zhdp-card-header-right">
						<span>去看详细分解</span>
						<van-icon name="arrow" />
					</div>
				</template>
				“林奎欧”读音为：lín kuí ōu，音调为：阳平 阳平 阴平，字音清晰、流畅，音调优美…字型为=>林：8画，部首为木，左右结构，奎：9画，部首为大，上下结构，欧：15画，部首为欠，左右结构，字形美观、协调、匀称。
			</ContentCard>
			<div class="geo-line geo-margin-top geo-margin-bottom"></div>
			<div class="geo-hilight-box">
				<div class="geo-hilight-box-title geo-text-red large">专家点评</div>
				<div class="geo-text-black">名字林奎欧周易卦象为坤卦（坤为地）。喻指柔顺和静，厚载之功；静守安顺，妄动招损。吉：得此卦者，宜顺从运势，以静制动，不宜独立谋事，顺从他人，一起合作，可成大事。</div>
			</div>
		</Card>
		<Card theme="center" title="名字潮流分析">
			<div class="zhdp-title">“奎欧”名字潮流分析（近50年）</div>
			<div class="zhdp-line-chart">
				<LineChart></LineChart>
				<div class="legend-box">
					<div class="legend-item legend-man">男性</div>
					<div class="legend-item legend-woman">女性</div>
				</div>
			</div>
			<div>
				基于大数据分析，近十年来，“奎欧”的男性使用率居多，比例占
			</div>
			<div class="zhdp-charts-box flex geo-margin-top">
				<div class="chart chart1 chart-sex">
					<img src="../../assets/icon_man.png" alt="">
					<div class="percent">59%</div>
				</div>
				<div class="chart chart2 chart-sex">
					<img src="../../assets/icon_woman.png" alt="">
					<div class="percent">40%</div>
				</div>
			</div>
			<div class="zhdp-title geo-margin-top geo-padding-top">“奎”在名字首字中使用排名趋势</div>
			<div class="zhdp-line-chart">
				<LineChart></LineChart>
				<div class="legend-box">
					<div class="legend-item legend-man">男性</div>
					<div class="legend-item legend-woman">女性</div>
				</div>
			</div>
			<div class="zhdp-title geo-margin-top">“欧”在名字首字中使用排名趋势</div>
			<div class="zhdp-line-chart">
				<LineChart></LineChart>
				<div class="legend-box">
					<div class="legend-item legend-man">男性</div>
					<div class="legend-item legend-woman">女性</div>
				</div>
			</div>
			<div class="geo-hilight-box geo-margin-top">
				<div>说明：该统计基于近13亿人的姓名数据，对名字在每个年代的使用情况做出排名，排名越高表示使用的人越多。</div>
			</div>
		</Card> -->
	</div>
</template>

<script>
import Card from '../Card.vue'
import CircleChart from '../CircleChart'
import RadarChart from '../RadarChart'
import LineChart from '../LineChart'
import ScoreCard from '../ScoreCard'
import ContentCard from '../ContentCard'
import detialJson from '../../utils/detail.json'

const scoreData = detialJson.data.free_names[0]

export default {
	name: 'ZhdpCard',
	props: {
		data: {
			type: Object,
			default: function() {
				return {}
			}
		},
		title: String
	},
	components: {
		Card,
		CircleChart,
		RadarChart,
		LineChart,
		ScoreCard,
		ContentCard
	},
	methods: {
		getTitle(score) {
			return {
				text: '匹配度',
				textStyle: {
					color: '#999999',
					fontSize: 12,
					lineHeight: 22,
				},
				subtext: score + '%',
				subtextStyle: {
					color: '#EA7373',
					fontSize: 16,
					lineHeight: 22
				},
				itemGap: 4, // 主副标题距离
				left: 'center',
				top: 32
			}
		}
	},
	computed: {
		zhdpData() {
			return this.data.free_names[this.$route.query.index]
		},
		mingArr() {
			if (this.zhdpData) {
				return this.zhdpData.ming.split('')
			}
			return []
		},
		pinYin() {
			if (this.zhdpData && this.zhdpData.wu_ge && this.zhdpData.xing) {
				return this.zhdpData.wu_ge.ping_yin.slice(this.zhdpData.xing.length)
			}
			return []
		},
		fanTi() {
			if (this.zhdpData && this.zhdpData.wu_ge && this.zhdpData.xing) {
				return this.zhdpData.wu_ge.fan_ti.slice(this.zhdpData.xing.length)
			}
			return []
		},
		wuXing() {
			if (this.zhdpData && this.zhdpData.wu_ge && this.zhdpData.xing) {
				return this.zhdpData.wu_ge.wu_xing.slice(this.zhdpData.xing.length)
			}
			return []
		},
		nameMatchs() {
			if (this.zhdpData && this.zhdpData.nicknames && this.zhdpData.english_names) {
				return [
					{
						label: '小名',
						name: this.zhdpData.nicknames[0].v,
						score: this.zhdpData.nicknames[0].s.slice(0, -1) - 0
					},
					{
						label: '英文名',
						name: this.zhdpData.english_names[0].v,
						score: this.zhdpData.english_names[0].s.slice(0, -1) - 0
					}
				]
			}
			return []
		},
		radarIndicator() {
			if (this.zhdpData && this.zhdpData.score_table) {
				return this.zhdpData.score_table.map(item => {
					return {
						name: this.$scoreTypes[item.name],
						max: 100
					}
				})
			}
			return []
		},
		radarData() {
			if (this.zhdpData && this.zhdpData.score_table) {
				return [{
					name: '',
					value: this.zhdpData.score_table.map(item => item.score)
				}]
			}
			return null
		}
	},
	data() {
		return {
			scoreData,
			// radarData: [
			// 	{
			// 		name: '',
			// 		value: [60, 50, 80, 100, 90]
			// 	}
			// ],
			// radarIndicator: [
			// 	{name: '名字内涵', max: 100},
			// 	{name: '周易卦象', max: 100},
			// 	{name: '五格数理', max: 100},
			// 	{name: '星座特征', max: 100},
			// 	{name: '生肖属相', max: 100},
			// 	{name: '国学起名', max: 100}
			// ],
		}
	}
}
</script>

<style lang="less" scoped>
@import '../../styles/var.less';
.zhdp-charts-box {
	.chart {
		width: 50%;
		height: 190px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.chart-tag {
			color: @red;
			font-size: 0.12rem;
			line-height: 0.2rem;
			padding: 0 4px;
			border-radius: 2px;
			border: 1px solid #ED4848;
			display: inline-block;
		}
		.chart-name {
			color: @black;
			line-height: 0.25rem;
			font-size: 0.18rem;
			font-weight: 500;
			margin-top: 8px;
			margin-bottom: 0.14rem;
		}
	}
	.chart-sex {
		height: 128px;
		font-size: 0.2rem;
		line-height: 0.28rem;
		font-weight: 500;
		.percent {
			margin-top: -0.1rem;
		}
		&.chart1 {
			color: #6DC8EC;
		}
		&.chart2 {
			color: #FF99C3;
		}
		img {
			width: 1.12rem;
			height: 1.12rem;
		}
	}
	.chart + .chart {
		border-left: 1px solid @borderColor;
	}
}
.zhdp-radar-box {
	width: 100%;
	height: 260px;
}
.zhdp-card-header-right {
	color: #999;
	line-height: 0.2rem;
	font-size: 0.14rem;
	display: flex;
	align-items: center;
}
.zhdp-title {
	font-size: 0.18rem;
	color: @black;
	line-height: 0.25rem;
}
.zhdp-line-chart {
	width: 100%;
	height: 266px;
	position: relative;
	.legend-box {
		color: #8c8c8c;
		font-size: 0.12rem;
		display: flex;
		position: absolute;
		top: 8px;
		left: 0.08rem;
		.legend-item {
			display: flex;
			align-items: center;
		}
		.legend-item::before {
			content: '';
			display: inline-block;
			width: 0.14rem;
			height: 0.14rem;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			margin-right: 4px;
		}
		.legend-man::before {
			background-image: url('../../assets/icon_legend_man.png');
		}
		.legend-woman::before {
			background-image: url('../../assets/icon_legend_woman.png');
		}
		.legend-item + .legend-item {
			margin-left: 12px;
		}
	}
}
</style>