<template>
	<div>
		<Card theme="center" :title="title">
			<div class="renruqiming-bar-box">
				<div class="title-box">名字用字“{{nameData.ming[0]}}”的职业分布信息</div>
				<div class="renruqiming-bar">
					<BarChart v-if="barData1.length" :data="barData1"></BarChart>
				</div>
			</div>
			<div class="renruqiming-content-card-box" v-for="(item, key) in firstItems" :key="key + 'first'">
				<ContentCard v-if="item && item.length !== 0" :title="key">
					<div class="renruqiming-horiz-scroll-wrap">
						<div class="geo-horiz-scroll-box">
							<div class="renruqiming-profile-box" v-for="(it, k) in item" :key="k">
								<div v-if="it.logo" class="renruqiming-avatar-box" :style="{backgroundImage: `url('${it.logo}')`}"></div>
								<div v-if="it.name" class="renruqiming-name-box geo-one-ellipsis">{{it.name}}</div>
							</div>
						</div>
					</div>
				</ContentCard>
			</div>
			<div class="renruqiming-bar-box renruqiming-margin-top">
				<div class="title-box">名字用字“{{nameData.ming[1]}}”的职业分布信息</div>
				<div class="renruqiming-bar">
					<BarChart v-if="barData2.length" :data="barData2"></BarChart>
				</div>
			</div>
			<div class="renruqiming-content-card-box" v-for="(item, key) in secondItems" :key="key + 'second'">
				<ContentCard v-if="item && item.length !== 0" :title="key">
					<div class="renruqiming-horiz-scroll-wrap">
						<div class="geo-horiz-scroll-box">
							<div class="renruqiming-profile-box" v-for="(it, k) in item" :key="k">
								<div v-if="it.logo" class="renruqiming-avatar-box" :style="{backgroundImage: `url('${it.logo}')`}"></div>
								<div v-if="it.name" class="renruqiming-name-box geo-one-ellipsis">{{it.name}}</div>
							</div>
						</div>
					</div>
				</ContentCard>
			</div>
		</Card>
	</div>
</template>

<script>
import Card from '../Card'
import ContentCard from '../ContentCard'
import BarChart from '../BarChart'
import { getMingruqiren } from '../../api/Main'

const types = []
export default {
	name: 'ZhouyiCard',
	props: {
		title: String,
		data: Object
	},
	components: {
		Card,
		ContentCard,
		BarChart
	},
	computed: {
		nameData() {
			return this.data.free_names[this.$route.query.index]
		},
		firstItems() {
			return this.itemData.first.item || {}
		},
		secondItems() {
			return this.itemData.second.item || {}
		},
		scoreList() {
			return this.data.score_list
		},
	},
	data() {
		return {
			types,
			itemData: {
				first: {
					item: {}
				},
				second: {
					item: {}
				}
			},
			barData1: [],
			barData2: []
		}
	},
	created() {
		this.getData()
	},
	methods: {
		async getData() {
			const {data} = await getMingruqiren({
				params: {
					ming_zi: this.nameData.ming
				}
			})
			this.itemData = data
			this.barData1 = Object.entries(data.first.map).filter(item => !!item[1]).map(item => {
				return {
					name: item[0],
					value: item[1]
				}
			})
			this.barData2 = Object.entries(data.second.map).filter(item => !!item[1]).map(item => {
				return {
					name: item[0],
					value: item[1]
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
@import '../../styles/var.less';
.renruqiming-bar-box {
	.title-box {
		font-size: 0.18rem;
		line-height: 0.25rem;
		margin-bottom: 8px;
		color: @black;
	}
	.renruqiming-bar {
		width: 100%;
		height: 322px;
	}
}
.renruqiming{
	&-profile-box {
		width: 0.57rem;
		display: inline-block;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		white-space: nowrap;
		.renruqiming-avatar-box {
			margin: 0 auto;
			width: 0.44rem;
			height: 0.44rem;
			background-color: @borderColor;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			border-radius: 50%;
			margin-bottom: 8px;
		}
		.renruqiming-name-box {
			margin: 0 auto;
			color: #323233;
		}
	}
	.renruqiming-profile-box + .renruqiming-profile-box {
		margin-left: 0.16rem;
	}
}
.renruqiming-horiz-scroll-wrap {
	height: 0.72rem;
	width: 100%;
	overflow: hidden;
}
.renruqiming-margin-top {
	margin-top: 0.55rem;
}
.renruqiming-content-card-box + .renruqiming-content-card-box {
	margin-top: 0.16rem;
}
</style>