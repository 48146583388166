<template>
	<div>
		<Card theme="center" :title="title + '评分'">
			<div class="geo-detail-more-type-card-header">
				<div class="geo-detail-more-type-card-header-chart">
					<CircleChart :title="$circleTitle(score)" :score="score" color="#EA7373" :radius="100" :barWidth="8"></CircleChart>
				</div>
			</div>
			<ContentCard title="周易卦象">
				<div class="flex zhouyi-symbol-box">
					<div v-for="(value, key) in zhouyiData.hexagram" :key="key">{{value}}</div>
				</div>
			</ContentCard>
			<ContentCard title="名字卦象解析">
				{{zhouyiData.word_analysis}}
			</ContentCard>
			<ContentCard title="卦象运势解析">
				<div class="geo-list-box">
					<div class="geo-list-row flex" v-for="item in zhouyiData.fortune_analysis" :key="item.word">
						<div class="geo-list-col1 geo-text-red">{{item.word}}</div>
						<div class="geo-list-col2 geo-border-bottom geo-padding-bottom">
							{{item.desc}}
						</div>
					</div>
				</div>
			</ContentCard>
		</Card>
	</div>
</template>

<script>
import Card from '../Card'
import ContentCard from '../ContentCard'
import CircleChart from '../CircleChart'
import WuxingCard from '../WuxingCard'
import AnalyCard from '../AnalyCard' 
import { getZhouyi } from '../../api/Main'
export default {
	name: 'ZhouyiCard',
	props: {
		title: String,
		data: Object,
		score: Number || String
	},
	components: {
		Card,
		ContentCard,
		CircleChart,
		WuxingCard,
		AnalyCard
	},
	computed: {
		typeData() {
			return this.data.free_names[this.$route.query.index].wu_ge.bi_hua
		},
		scoreList() {
			return this.data.score_list
		},
	},
	data() {
		return {
			zhouyiData: {
				score: null,
				word_analysis: '',
				fortune_analysis: [],
				hexagram: {
					ben: '',
					bian: '',
					hu: ''
				}
			}
		}
	},
	created() {
		this.getData()
	},
	methods: {
		async getData() {
			const {data} = await getZhouyi({
				params: {
					surname_stroke: this.typeData[0],
					first_stroke: this.typeData[1],
					second_stroke: this.typeData[2]
				}
			})
			this.zhouyiData = data
		}
	}
}
</script>

<style lang="less" scoped>
.zhouyi-symbol-box {
	font-size: 1.1rem;
	line-height: 1;
	// margin-top: -0.06rem;
	// margin-left: -0.1rem;
	// margin-right: -0.17rem;
	// margin-bottom: -0.17rem;
	color: #999999;
	overflow: hidden;
}
</style>