import xingzuo from './xingzuo.vue'
import shengxiao from './shengxiao.vue'
import guoxue from './guoxue.vue'
import wuge from './wuge.vue'
import xinlixue from './xinlixue.vue'
import yinxingyi from './yinxingyi.vue'
import zhouyi from './zhouyi.vue'
import renruqiming from './renruqiming.vue'
import zhdp from './zhdp.vue'

export default {
	xingzuo,
	shengxiao,
	guoxue,
	wuge,
	xinlixue,
	yinxingyi,
	zhouyi,
	renruqiming,
	zhdp
}