import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import './utils/rem'
import './styles/index.less'
import vant from 'vant'
import http from './utils/request'
import VueClipboard from 'vue-clipboard2'
import VueObserveVisibility from 'vue-observe-visibility'
// import './utils/datepicker'
import './styles/datepicker.less'

Vue.use(VueObserveVisibility)

Vue.use(VueClipboard)

Vue.use(vant)

Vue.config.productionTip = false

Vue.prototype.$http = http
Vue.prototype.$wuxingmap = {
  '金': 'jin',
  '木': 'mu',
  '水': 'shui',
  '火': 'huo',
  '土': 'tu'
}
Vue.prototype.$scoreTypes = {
  'guo_xue': '国学起名',
  'yin_xing_yi': '名字内涵',
  'sheng_xiao': '生肖属相',
  'san_cai_wu_ge': '五格数理',
  'xing_zhuo': '星座特征',
  'zhou_yi': '周易卦象'
}
Vue.prototype.$scoremap = {
  'guo_xue': {
    name: '国学得分',
    color: '#BDDAF7',
    tcolor: '#598CC0',
  },
  'yin_xing_yi': {
    name: '名字内涵',
    color: '#F8B8B8',
    tcolor: '#EA7373',
  },
  'sheng_xiao': {
    name: '生肖属相',
    color: '#F0D998',
    tcolor: '#D5AE3D',
  },
  'san_cai_wu_ge': {
    name: '五格数理',
    color: '#AFE2D0',
    tcolor: '#55AD8F',
  },
  'xing_zhuo': {
    name: '星座特征',
    color: '#B2B0F3',
    tcolor: '#8180DE',
  },
  'zhou_yi': {
    name: '周易卦象',
    color: '#F2CCB3',
    tcolor: '#DA8854',
  }
}
Vue.prototype.$baseinfo = {
  '1': '男',
  '2': '女'
}

Vue.prototype.$circleTitle = (score) => {
  return {
    text: score + '分',
    textStyle: {
      color: '#EA7373',
      fontSize: 20,
    },
    left: 'center',
    top: 'center'
  }
}

Vue.prototype.$EventBus = new Vue()

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default app
