<template>
	<div class="geo-date-picker-box">
		<!-- 带时辰分钟 -->
		<textarea rows="1" ref="picker" class="van-field__control" :id="id" type="text" data-confirm="false" :data-toid-date="dateid" :data-toid-hour="hourid" :data-toid-minute="minuteid" name="input_date" data-hour="" date-minite="" :placeholder="placeholder" readonly="readonly" />
		<input type="hidden" :id="dateid" name="date">
		<input type="hidden" :id="hourid" name="hour">
		<input type="hidden" :id="minuteid" name="minute">
	</div>
</template>

<script>
import autosize from '../utils/autosize';
import { geoDatePicker } from '../utils/datepicker'

export default {
	name: 'datepicker',
	props: ['id', 'placeholder', 'dateid', 'hourid', 'minuteid', 'min', 'max'],
	data() {
		return {
			
		}
	},
	mounted() {
		console.log(new geoDatePicker())
		this.init()
	},
	methods: {
		init() {
			autosize(this.id)
			const datePicker = new geoDatePicker().init({
				id: '#' + this.id,
				minY: this.min || 1940,
				maxY: this.max || 2030,
				callback: (date) => {
					console.log(date)
					const isLunar = this.$refs.picker.getAttribute('data-type')
					let data = {}
					if (isLunar === '0') {
						data = {
							y: date.yy,
							m: date.mm,
							d: date.dd,
							h: date.hh,
							i: date.ii,
							isLunar: 0,
							text: this.$refs.picker.value
						}
					} else {
						data = {
							y: date._yy,
							m: date._mm,
							d: date._dd,
							h: date._hh,
							i: date._ii,
							isLunar: 1,
							text: this.$refs.picker.value
						}
					}
					this.$emit('change', data)
				}
			});
		},
		clear() {
			this.$refs.picker.value = ''
		}
	}
}
</script>

<style lang="less" scoped>
.geo-date-picker-box {
	width: 100%;
}
</style>