<template>
	<v-chart :options="optionData" :init-options="initOptions"></v-chart>
</template>

<script>
import ECharts from 'vue-echarts'
import * as echarts from 'echarts/dist/echarts.js'
import 'echarts/lib/chart/radar'

export default {
	name: 'RadarChart',
	components: {
		'v-chart': ECharts
	},
	props: {
		data: {
			type: Array,
			default: function() {
				return []
			}
		},
		indicator: {
			type: Array,
			default: function() {
				return []
			}
		}
	},
	computed: {
		optionData() {
			this.options.series[0].data = this.data
			this.options.radar.indicator = this.indicator
			return this.options
		}
	},
	data() {
		return {
			initOptions: {
        renderer: 'canvas'
      },
			circleData: {},
			options: {
				radar: {
					// shape: 'circle',
					name: {
						show: true,
						textStyle: {
							color: '#999',
							fontSize: 12
						}
					},
					axisLine: {
						lineStyle: {
							color: '#BFBFBF',
						},
					},
					splitArea : {
						show : false,
						areaStyle : {
							color: '#E87', // 图表背景的颜色
						},
					},
					splitLine : {
						show : true,
						lineStyle : {
							width : 1,
							color : '#E9E9E9', // 设置网格的颜色
						},
					},
					indicator: []
				},
				series: [
					{
						type: 'radar',
						symbol: 'circle',
						symbolSize: [8, 8],
						areaStyle: {
							normal: {
								color: '#EA737380'
							}
						},
						itemStyle: {
							normal: {
								color: '#EA7373',
								borderColor: '#EA7373',
								borderWidth: 0.5,
								color: 'white',
								lineStyle: {
									color: '#EA7373',
								},
							},
						},
						data: []
					}
				]
			}
		}
	}
}
</script>

<style lang="less">
.echarts {
	width: 100%;
	height: 100%;
}
</style>

<style lang="less" scoped>

</style>