import app from '../main'
import axios from 'axios'
import { Toast } from 'vant'
import router from '../router'

const tokenName = 'access_token'

axios.defaults.baseURL = 'https://zhouyi.tinywan.com'

axios.interceptors.request.use((req) => {
	let token = window.localStorage.getItem(tokenName) || ''
	req.headers.Authorization = token
	return req
}, (error) => {
	return Promise.reject(error)
})

axios.interceptors.response.use((res) => {
	if (res.status < 300 && res.status > 199) {
		return res.data
	}
	if (res.status === 401) {
		window.localStorage.removeItem(tokenName)
	}
	if (res.data.code !== 0) {
		Toast.fail(res.data.msg || 'unknown error code ' + code)
	}
	return res.data
}, (error) => {
	console.log(error.response)
	const { status, data } = error.response
	if (status === 401 || status === 402) {
		window.localStorage.removeItem(tokenName)
		if (window.location.pathname !== '/user') {
			// router.push('/user?showLogin=1')
			app.$EventBus.$emit('showLogin', true)
		}
	} else {
		data.msg && Toast.fail(data.msg)
	}
	return Promise.reject(error)
})

const http = (options) => {
	const instance = axios(options)
	return instance
}

export default http