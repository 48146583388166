<template>
	<div class="edit-comment-container">
		<div class="edit-comment-tit">评价一下</div>
		<div class="edit-comment-score-box">
			<div class="edit-comment-score-label">满意度：</div>
			<div class="geo-stars">
				<van-icon @click="makeScore(item)" v-for="item in [1, 2, 3, 4, 5]" :name="(item <= star) ? 'star' : 'star-o'" :key="item" />
			</div>
		</div>
		
		<div class="edit-comment-input-box">
			<van-field
				v-model="content"
				rows="6"
				type="textarea"
				maxlength="100"
				placeholder="与更多宝妈宝爸分享喜悦…"
				show-word-limit
			/>
		</div>
		<van-button class="edit-comment-submit-btn" color="#ED4848" round @click="submit">提交</van-button>
	</div>
</template>

<script>
import { Toast } from 'vant'
import { addComment } from '../api/User'
export default {
	name: 'EditComment',
	data() {
		return {
			content: '',
			star: 5
		}
	},
	methods: {
		makeScore(star) {
			this.star = star
		},
		async submit() {
			this.content = this.content.trim()
			if (!this.content) return Toast({ message: '请输入评价内容' })
			const {code, data, msg} = await addComment({
				data: {
					content: this.content,
					star: this.star
				}
			})
			if (code === 0) {
				Toast.success(msg)
				this.content = ''
				this.star = 0
				this.$emit('close')
			}
		}
	}
}
</script>

<style lang="less" scoped>
.edit-comment-container {
	width: 100%;
	padding: 0 0.12rem 0.2rem;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	.edit-comment-tit {
		line-height: 0.48rem;
		text-align: center;
		font-size: 0.16rem;
		font-weight: 500;
		color: #333;
	}
	.edit-comment-score-box {
		display: flex;
		align-items: center;
		font-size: 0.14rem;
		line-height: 0.2rem;
		color: #666;
		margin-bottom: 0.2rem;
		.geo-stars {
			display: flex;
			align-items: center;
		}
		i {
			font-size: 0.18rem;
			margin-left: 10px;
		}
	}
	.edit-comment-input-box {
		// height: 2.38rem;
		background: #F7F8FA;
		font-size: 0.14rem;
		color: #666;
	}
	/deep/ .van-cell {
		background-color: transparent;
	}
	button.edit-comment-submit-btn {
		width: 0.52rem;
		height: 0.24rem;
		font-size: 0.12rem;
		padding: 0;
		position: absolute;
		top: 0.12rem;
		right: 0.16rem;
	}
}
</style>