const detailListData = [
	{
		name: '综合点评',
		id: 'zhdp',
		alias: 'zong_he',
		icon: 'icon_zhdp'
	},
	{
		name: '音形义详解',
		id: 'yinxingyi',
		alias: 'yin_xing_yi',
		icon: 'icon_yinxingyi'
	},
	{
		name: '心理学起名',
		id: 'xinlixue',
		alias: 'xin_li_xue',
		icon: 'icon_xinlixue'
	},
	{
		name: '国学起名',
		id: 'guoxue',
		alias: 'guo_xue',
		icon: 'icon_guoxue'
	},
	{
		name: '星座起名',
		id: 'xingzuo',
		alias: 'xing_zhuo',
		icon: 'icon_xingzuo'
	},
	{
		name: '生肖吉祥',
		id: 'shengxiao',
		alias: 'sheng_xiao',
		icon: 'icon_shengxiao'
	},
	{
		name: '三才五格',
		id: 'wuge',
		alias: 'san_cai_wu_ge',
		icon: 'icon_wuge'
	},
	{
		name: '周易卦象',
		id: 'zhouyi',
		alias: 'zhou_yi',
		icon: 'icon_zhouyi'
	},
	{
		name: '人如其名',
		id: 'renruqiming',
		alias: 'ren_ru_qi_ming',
		icon: 'icon_renruqiming'
	},
]
export default detailListData

let detailMapObj = {}
detailListData.forEach(item => {
	detailMapObj[item.id] = item.alias
})

export const detalMap = detailMapObj