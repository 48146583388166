<template>
	<div class="geo-detail-more border-box">
		<div class="geo-detail-more-header">
			<div class="name-box">
				<NameCard v-if="detailData.free_names[$route.query.index]" :data="detailData.free_names[$route.query.index]" :wuxing="false" :score="detailData.score_list.zong_he"></NameCard>
			</div>
			<div class="tab-box">
				<van-tabs v-model="activeTab" @change="changeTab">
					<van-tab v-for="tab in details" :key="tab.name" :title="tab.name" :name="tab.id"></van-tab>
				</van-tabs>
				<div class="tab-mask"></div>
			</div>
		</div>
		<div class="geo-detail-more-content border-box">
			<component :score="scoreList[detalMap[activeTab]]" :data="detailData" :is="cards[activeTab]" :title="getTitle(activeTab)"></component>
		</div>
		<div class="geo-detail-more-footer border-box">
			<div class="footer-item flex border-box" v-for="(item) in details" :key="item.id">
				<div class="left flex">
					<div class="name-box flex">
						<div class="icon">
							<img :src="require('../assets/' + item.icon + '.png')" alt="">
						</div>
						<div class="name">{{item.name}}</div>
					</div>
					<div v-if="detailData" class="score">{{`${scoreList[item.alias]}分`}}</div>
				</div>
				<div class="right">
					<div @click="gotoTabFromFooter(item.id)" class="geo-detail-btn flex" :class="{ active: item.id === activeTab }">
						<span>查看详情</span>
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NameCard from '../components/NameCard'
import details, { detalMap } from '../utils/details'
import { getOrderDetail } from '../api/Main'
import cards from '../components/detail'
export default {
	name: 'DetailMore',
	components: {
		NameCard
	},
	data() {
		return {
			cards,
			details,
			detalMap,
			// detailData: {},
			activeTab: 'zhdp',
			analy: {
				wu_xing: {},
			}
		}
	},
	computed: {
		detailData() {
			return this.$store.state.nameDetail
		},
		target() {
			return this.detailData.free_names[this.$route.query.index]
		},
		freeName() {
			return this.$store.state.freeName
		},
		scoreList() {
			const listObj = {}
			this.target && this.target.score_list && this.target.score_list.forEach(item => {
				listObj[item.name] = item.score
			})
			return listObj
		}
	},
	created() {
		this.getData()
	},
	methods: {
		gotoTabFromFooter(id) {
			window.pageYOffset = 0;
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
			this.activeTab = id
		},
		getTitle(id) {
			let tab = details.filter(item => (item.id === id))[0]
			return tab.name
		},
		changeTab(tab) {
			console.log(tab)
		},
		async getData() {
			// const {code, msg, data} = detailData
			const { code, msg, data } = await getOrderDetail(this.$route.params.id)
			if (code === 0) {
				console.log(data)
				console.log(data.free_names[this.$route.query.index])
				// this.detailData = data
				this.$store.commit('CHANGE_NAME_DETAIL', {data})
				this.$store.commit('CHANGE_FREE_NAME', {data: data.free_names[this.$route.query.index]})
				// this.analy = data.analysis_list
			} else {
				Toast.fail(msg || 'unknown error code ' + code)
			}
		},
	}
}
</script>

<style lang="less" scope>
@import '../styles/var.less';
.geo-detail-more {
	min-height: 100vh;
	background-color: @bgcolor;
	.geo-detail-more-header {
		padding-top: 0.16rem;
		background-color: white;
		.name-box {
			min-height: 0.85rem;
			padding: 0.21rem 0.32rem 0.22rem;
			background-image: url('../assets/score_bg.png');
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: center;
		}
		.tab-box {
			position: relative;
			.tab-mask {
				position: absolute;
				right: 0;
				top: 0;
				width: 0.44rem;
				height: 100%;
				background: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 51%, rgba(255, 255, 255, 0) 100%);
				opacity: 0.95;
			}
			/deep/ .van-tab:nth-of-type(9) {
				padding-right: 30px;
			}
		}
	}
	.geo-detail-more-content {
		padding: 0.3rem 0.16rem 0.16rem;
	}
	.geo-detail-more-footer {
		background: #944343;
		padding: 0.2rem 0.16rem;
		.footer-item + .footer-item {
			margin-top: 0.12rem;
		}
		.footer-item {
			background: white;
			width: 100%;
			height: 0.5rem;
			border-radius: 4px;
			padding: 0 0.1rem 0 0.14rem;
			.left {
				width: 50%;
				.name-box {
					.icon {
						width: 0.24rem;
						height: 0.24rem;
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						margin-right: 4px;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.name {
						font-size: 0.16rem;
						color: @black;
						font-weight: 500;
					}
				}
				.score {
					font-size: 0.18rem;
					color: #FF5555;
				}
			}
			.right {
				.geo-detail-btn {
					box-shadow: none;
					width: 0.99rem;
					height: 0.32rem;
					font-size: 0.14rem;
					line-height: 0.2rem;
					background-image: url('../assets/btn_bg.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: center;
					padding: 0;
					color: white;
					justify-content: center;

				}
				.geo-detail-btn.active {
					background-image: url('../assets/btn_bg_active.png');
					pointer-events: none;
				}
			}
		}
	}
}
</style>