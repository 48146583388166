<template>
  <div id="app">
    <router-view />
    <Toolbar v-if="$route.name !== 'Statement'"></Toolbar>
    <van-popup round close-on-popstate closeable v-model="showLogin">
			<div class="flex user-login-wrapper" @click.stop>
				<Login @close="showLogin = false"></Login>
			</div>
		</van-popup>
  </div>
</template>

<script>
import {getWxConfig, setPV} from './api/Main'
import Toolbar from './components/Toolbar'
import Login from './components/Login'
import handleLogin from './utils/handleLogin'
export default {
  data() {
    return {
      showLogin: false
    }
  },
  components: {
    Toolbar,
    Login
  },
  created() {
    handleLogin(this)
  },
  mounted() {
    this.record()
    if (wx) {
      this.getWxConfig(wx)
    }
    this.$EventBus.$on('showLogin', (isshow) => {
      this.showLogin = isshow
    })
  },
  methods: {
    async record () {
      const {code, data, msg} = await setPV()
    },
    async getWxConfig(wx) {
      const shareConfig = {
        title: '名正取名', // 分享标题
        desc: '赐子千金,不如教子一艺;教子一艺,不如赐子好名。', // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://zhouyi.tinywan.com/assets/common/img/wechat_share.jpg', // 分享图标
        success: function () {
          // 设置成功
        }
      }
      const {data} = await getWxConfig()
      // data.debug = true
      data.jsApiList.push('onMenuShareTimeline')
      wx.config(data)
      wx.ready(() => {
        wx.updateAppMessageShareData(shareConfig)
        wx.updateTimelineShareData(shareConfig)
        wx.onMenuShareTimeline(shareConfig)
      })
      wx.error(function(res){
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.error(res)
      });
    }
  }
}
</script>

<style lang="less">
body {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
#app {
  padding-bottom: 50px;
  width: 100%;
  overflow: hidden;
}
</style>
