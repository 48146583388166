import http from '../utils/request'

const base = '/api/v1'
export function getToken(options) {
	return http(Object.assign({
		url: `${base}/token`,
		method: 'POST'
	}, options))
}

export function getUserInfo(options) {
	return http(Object.assign({
		url: `${base}/user`
	}, options))
}

export function getUserComment(options) {
	return http(Object.assign({
		url: `${base}/user/comment`	
	}, options))
}

export function collectName(options) {
	return http(Object.assign({
		url: `${base}/user/name-collect`,
		method: 'POST'	
	}, options))
}

export function cancelCollectName(options) {
	return http(Object.assign({
		url: `${base}/user/name-collect-cancel`,
		method: 'POST'	
	}, options))
}

export function getCollectNames(options) {
	return http(Object.assign({
		url: `${base}/user/name-collect`,
	}, options))
}

// 获取评价列表
export function getCommentHistory(options) {
	return http(Object.assign({
		url: `${base}/user/comment-list`,
	}, options))
}

// 添加评价
export function addComment(options) {
	return http(Object.assign({
		url: `${base}/comment`,
		method: 'POST'
	}, options))
}

// 删除评价
export function rmComment(options, uid) {
	return http(Object.assign({
		url: `${base}/user/comment-delete/${uid}`,
		method: 'DELETE'
	}, options))
}

// 提交预约
export function addBooking(options) {
	return http(Object.assign({
		url: `${base}/reservation`,
		method: 'POST'
	}, options))
}

// 预约信息
export function getBooking(options) {
	return http(Object.assign({
		url: `${base}/user/reservation`,
	}, options))
}

// 测分收藏
export function collectScore(options, id) {
	return http(Object.assign({
		url: `${base}/user/test-score-collect/${id}`
	}, options))
}

// 取消测分收藏
export function cancelCollectScore(options, id) {
	return http(Object.assign({
		url: `${base}/user/test-score-cancel/${id}`,
	}, options))
}

// 历史测分
export function getScoreHistory(options) {
	return http(Object.assign({
		url: `${base}/user/history-test-score`,
	}, options))
}

// 支付宝
export function getZhifubao(options, id) {
	return http(Object.assign({
		url: `${base}/user/alipay/${id}`,
	}, options))
}

// 支付宝
export function getWechat(options, id) {
	return http(Object.assign({
		url: `${base}/user/wechat/${id}`,
	}, options))
}