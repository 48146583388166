<template>
	<div class="user-name-collection-container">
		<div class="user-name-collection-list" v-if="isList">
			<Card
				v-for="(item, index) in nameList"
				:orderIndex="index"
				:key="item.id"
				:nameId="item.id"
				theme="cancelcollect"
				title="取消收藏"
				style="position: relative;"
				@click.native="gotoDetail(item)"
				@cancelSuccess="cancelSuccess">
				<NameCard :wuxing="false" :sex="item.sex" :birth="item.birth" :data="item"></NameCard>
			</Card>
		</div>
		<div class="geo-empty-container" v-if="isEmpty">
			<van-empty
				class="custom-image"
				:image="require('../assets/empty_collection.png')"
				description="暂无收藏" />
		</div>
	</div>
</template>

<script>
import Card from '../components/Card'
import NameCard from '../components/NameCard'
import detialJson from '../utils/detail.json'
import { getCollectNames } from '../api/User'

const scoreData = detialJson.data.free_names[0]
export default {
	name: 'UserNameCollection',
	components: {
		Card,
		NameCard,
	},
	data() {
		return {
			isList: false,
			isEmpty: false,
			scoreData,
			nameList: []
		}
	},
	created() {
		this.getNames()
	},
	methods: {
		gotoDetail(item) {
			// console.log(item)
			this.$router.push(`/detailmore/${item.order_id}?index=${item.free_name_index}`)
		},
		cancelSuccess(index) {
			this.nameList.splice(index, 1)
			this.isEmpty = !this.nameList.length
		},
		async getNames() {
			const {code, data, msg} = await getCollectNames()
			console.log(data)
			if (code === 0) {
				this.isList = !!data.length
				this.isEmpty = !data.length
				this.isList && (this.nameList = data.map(item => {
					const xings = item.xing.split('').map(item => {
						return {
							v: item,
							s: ''
						}
					})
					const mings = item.ming.split('').map(item => {
						return {
							v: item,
							s: ''
						}
					})
					return Object.assign({
						text: [...xings, ...mings]
					}, item)
				}))
			} else if (code === 404) {
				this.isEmpty = true
			}
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.user-name-collection-container {
	background: @bgcolor;
	min-height: 100vh;
	.user-name-collection-list {
		padding: 0.16rem;
	}
}
</style>