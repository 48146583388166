<template>
	<div class="geo-page-box geo-detail">
		<Card class="geo-detail-base-card" theme="center" title="基本信息">
			<div class="geo-detail-base">
				<div class="flex geo-detail-base-row">
					<div class="geo-detail-base-col1">姓氏:</div>
					<div class="geo-detail-base-col2">{{detailData.surname}}</div>
				</div>
				<div class="flex geo-detail-base-row">
					<div class="geo-detail-base-col1">性别:</div>
					<div class="geo-detail-base-col2">{{$baseinfo[detailData.sex]}}</div>
				</div>
				<div class="flex geo-detail-base-row">
					<div class="geo-detail-base-col1">出生时间:</div>
					<!-- <div class="geo-detail-base-col2" v-if="detailData.birth_year">{{`${detailData.birth_year}-${detailData.birth_month}-${detailData.birth_day} ${detailData.birth_hour}:${detailData.birth_minute}`}}</div> -->
					<div class="geo-detail-base-col2">{{detailData.date_list.is_lunar ? detailData.date_list.brith_lunar_chinese : detailData.date_list.brith_solar}}</div>
				</div>
				<div class="flex geo-detail-base-row">
					<div class="geo-detail-base-col1">出生地:</div>
					<div class="geo-detail-base-col2">{{detailData.birth_place}}</div>
				</div>
			</div>
		</Card>
		<Card theme="center" title="用字分析">
			<ContentCard title="国学起名用字分析">
				<div>{{analy.guo_xue && analy.guo_xue.text}}</div>
			</ContentCard>
			<ContentCard title="五行强弱分析">
				<WuxingCard :analy="analy"></WuxingCard>
			</ContentCard>
			<ContentCard title="五格用字分析">
				<div>{{analy.wu_ge && analy.wu_ge.text}}</div>
			</ContentCard>
			<ContentCard title="周易用字分析">
				<div>{{analy.zhou_yi && analy.zhou_yi.text}}</div>
			</ContentCard>
			<ContentCard title="生肖用字分析">
				<div>{{analy.sheng_xiao && analy.sheng_xiao.text}}</div>
			</ContentCard>
			<ContentCard title="星座用字分析">
				<div>{{analy.xing_zuo && analy.xing_zuo.text}}</div>
			</ContentCard>
		</Card>
		<Card v-for="(item, detailIndex) in detailData.free_names" :key="item.name_id" :nameId="item.name_id" :theme="detailIndex ? 'cancelcollect' : 'collect'" :title="detailIndex ? '已收藏' :'收藏'">
			<NameCard :data="item"></NameCard>
			<div class="geo-line"></div>
			<ScoreCard :table="item.score_table"></ScoreCard>
			<div class="geo-line"></div>
			<div class="geo-detail-other-names">
				<div class="other english-names">
					<div class="header flex">
						<div class="tag">小名</div>
						<div class="intro">与大名的匹配度</div>
					</div>
					<div class="con flex">
						<div class="ele flex" v-for="(item) in item.nicknames" :key="item.v">
							<div class="name">{{item.v}}</div>
							<div class="similar">{{item.s}}</div>
						</div>
					</div>
				</div>
				<div class="other english-names">
					<div class="header flex">
						<div class="tag">英文名</div>
						<div class="intro">与大名的匹配度</div>
					</div>
					<div class="con flex">
						<div class="ele flex" v-for="(item) in item.english_names" :key="item.v">
							<div class="name">{{item.v}}</div>
							<div class="similar">{{item.s}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="geo-line"></div>
			<div class="geo-detail-ming">
				<div class="ming-font flex" v-for="(font, index) in item.text.slice(1)" :key="font.v + index">
					<template v-if="font.v && font.z">
						<div class="geo-name-font-box">
							<div class="geo-name-font">{{font.v}}</div>
						</div>
						<div class="info">{{font.d}}</div>
					</template>
				</div>
			</div>
			<div class="geo-detail-btns flex">
				<div class="geo-btn red size1">
					<div class="geo-btn-bg" @click="gotoDetailMore(detailIndex)">名字详情</div>
				</div>
				<div class="geo-btn yellow size2" @click="gotoBooking">
					<div class="geo-btn-bg geo-detail-btn-book">
						<div>预约大师</div>
						<small>（为宝宝取满分美名）</small>
					</div>
				</div>
			</div>
		</Card>
		<Card v-for="(item) in detailData.good_names" :key="item.name_id" theme="collect" title="收藏" style="position: relative;">
			<NameCard :data="payData"></NameCard>
			<div class="geo-line"></div>
			<ScoreCard :table="payData.score_table"></ScoreCard>
			<div class="geo-detail-pay-mask"></div>
			<div class="geo-detail-btns flex">
				<div class="geo-btn red size1">
					<div class="geo-btn-bg" @click="gotoPay(item.name_id)">解锁高分美名</div>
				</div>
				<div class="geo-btn yellow size2" @click="gotoBooking">
					<div class="geo-btn-bg geo-detail-btn-book">
						<div>预约大师</div>
						<small>（为宝宝取满分美名）</small>
					</div>
				</div>
			</div>
		</Card>
		<van-popup round close-on-popstate closeable v-model="ispay" @close="curPayId = ''">
			<div class="geo-pay-container">
				<div class="geo-pay-title">解锁高分美名</div>
				<div class="geo-pay-desc">赐子千金，不如教子一艺；教子一艺，不如赐子一名。</div>
				<div class="geo-pay-price-box">
					<div class="price-label">限时优惠价</div>
					<div class="price1">1.99元</div>
					<div class="price2">原价：169.9元</div>
				</div>
				<div class="geo-pay-intro">
					<div>请选择在线支付方式</div>
					<div>支付完成后即可解锁以下所有内容</div>
				</div>
				<div class="geo-pay-btns flex">
					<van-button class="wx" color="#1bbc57" round :icon="require('../assets/icon_wexin.png')"  @click="gotoWx()">微信支付</van-button>
					<van-button @click="gotoZifubao()" class="zfb" color="#189ee2" round :icon="require('../assets/icon_zhifubao.png')">支付宝支付</van-button>
				</div>
			</div>
		</van-popup>
		<van-overlay :lock-scroll="true" :z-index="99999" :show="showGuide" @click="showGuide = false">
			<img class="geo-browser-guide" src="../assets/guide.png" alt="">
		</van-overlay>
	</div>
</template>

<script>
import { Toast } from 'vant'
import Card from '../components/Card'
import ContentCard from '../components/ContentCard'
import NameCard from '../components/NameCard'
import ScoreCard from '../components/ScoreCard'
import WuxingCard from '../components/WuxingCard'
import detailData from '../utils/detail.json'
import { getOrderDetail } from '../api/Main'
import { getUserInfo, getZhifubao, getWechat } from '../api/User'
import cloneDeep from 'lodash.clonedeep'
import { isWx } from '../utils/utils'
export default {
	name: 'GeoDetail',
	components: {
		Card,
		ContentCard,
		NameCard,
		ScoreCard,
		WuxingCard
	},
	data() {
		return {
			ispay: false,
			detailData: {
				date_list: {}
			},
			analy: {
				wu_xing: {},
			},
			payData: {},
			showGuide: false,
			curPayId: '',
			dataInterval: null
		}
	},
	created() {
		this.getData()
		// this.dataInterval = setInterval(() => {
		// 	this.getData()
		// }, 2000)
	},
	mounted() {
		document.addEventListener("visibilitychange", this.handleShow)
	},
	beforeDestroy() {
		document.removeEventListener("visibilitychange", this.handleShow)
		// clearInterval(this.dataInterval)
	},
	methods: {
		handleShow() {
			if (document.visibilityState === 'visible') {
				this.getData()
			}
		},
		async gotoWx() {
			if (wx) {
				const {data, code, msg} = await getWechat({}, this.curPayId)
				if (code === 0) {
					console.log(data)
					this.ispay = false
					this.curPayId = ''
					wx.ready(() => {
						wx.chooseWXPay({
							appId: data.appId,
							timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
							nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 
							package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
							signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
							paySign: data.paySign, // 支付签名
							success: (res) => {
								// 支付成功后的回调函数
								this.getData()
							}
						})
					})
				} else {
					Toast.fail(msg)
				}
			}
		},
		async gotoZifubao() {
			if (isWx()) {
				this.showGuide = true
				return
			}
			const {data, code, msg} = await getZhifubao({}, this.curPayId)
			if (code === 0) {
				console.log(data)
				this.ispay = false
				this.curPayId = ''
				window.location.href = data.url
			} else {
				Toast.fail(msg)
			}
		},
		async gotoPay(id) {
			const {code} = await getUserInfo()
			if (code === 0) {
				this.ispay = true
				this.curPayId = id
			}
		},
		gotoBooking() {
			const token = window.localStorage.getItem('access_token')
			if (!token) {
				// return this.$router.push('/user?showLogin=1')
				return this.$EventBus.$emit('showLogin', true)
			}
			this.$router.push('/user/booking?redirect=' + encodeURIComponent(this.$route.fullPath))
		},
		async getData() {
			// const {code, msg, data} = detailData
			const { code, msg, data } = await getOrderDetail(this.$route.params.id)
			if (code === 0) {
				this.detailData = data
				this.$store.commit('CHANGE_NAME_DETAIL', {data})
				this.analy = data.analysis_list
				this.payData = cloneDeep(data.free_names[0])
				this.payData.score = '??'
				this.payData.text.forEach((item, index) => {
					if (index !== 0) {
						item.v = '?'
						item.s = '?'
					}
				})
				Object.keys(this.payData.score_table).forEach(item => {
					this.payData.score_table[item] = '?'
				})
			} else {
				Toast.fail(msg || 'unknown error code ' + code)
			}
		},
		gotoDetailMore(index) {
			this.$router.push(`/detailmore/${this.$route.params.id}?index=${index}`)
		}
	}
}
</script>

<style lang="less">
.geo-detail-base-card {
	background-image: url('../assets/card_bg.png');
	background-repeat: no-repeat;
	background-position: 0 0.3rem;
	background-size: contain;
	.geo-detail-base-row {
		justify-content: start;
		.geo-detail-base-col1 {
			min-width: 0.7rem;
		}
	}
	.geo-detail-base-row + .geo-detail-base-row {
		margin-top: 8px;
	}
}
</style>

<style lang="less" scoped>
@import '../styles/var.less';
.geo-detail-other-names {
	padding: 0.16rem 0;
	.other + .other {
		margin-top: 0.16rem;
	}
	.other {
		.header {
			justify-content: start;
			.tag {
				background: #D98282;
				color: white;
				font-size: 0.14rem;
				line-height: 0.24rem;
				height: 0.24;
				padding: 0 0.16rem;
				margin-right: 8px;
			}
			.intro {
				font-size: 0.12rem;
				color: #999;
			}
		}
		.con {
			justify-content: start;
			margin-top: 0.16rem;
			.ele + .ele {
				border-left: 1px solid #E5E5E5;
			}
			.ele {
				font-size: 0.12rem;
				padding: 0 8px;
				.name {
					color: @black;
					font-weight: @fw500;
				}
				.similar {
					color: @black666;
					margin-left: 4px;
				}
			}
			.ele:first-child {
				padding-left: 0;
				.similar {
					color: @red;
				}
			}
		}
	}
}
.geo-detail-ming {
	padding: 0.16rem 0;
	.ming-font + .ming-font {
		margin-top: 0.16rem;
	}
	.info {
		margin-left: 0.12rem;
		overflow : hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}
.geo-detail-btns {
	margin-top: 0.16rem;
	.geo-detail-btn-book {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		div {
			font-size: 0.14rem;
			line-height: 0.2rem;
		}
		small {
			display: block;
			font-size: 0.1rem;
			line-height: 0.14rem;
			opacity: .5;
			margin-top: -1px;
		}
	}
}
.geo-detail-pay-mask {
	width: 100%;
	height: 2.5rem;
	position: absolute;
	bottom: 0.7rem;
	left: 0;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #FFFFFF 100%);
	filter: blur(4px);
}
</style>