<template>
	<van-tabbar :safe-area-inset-bottom="true" @change="tabChange" v-model="active" inactive-color="#BDBDBD" active-color="#ED4848">
		<van-tabbar-item
			@click="handleItem"
			placeholder
			safe-area-inset-bottom
			v-for="item in tabs"
			:key="item.name">
			<span>{{item.name}}</span>
			<template #icon="props">
				<img :src="props.active ? item.icon_active : item.icon" />
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	name: 'Toolbar',
	data() {
		return {
			active: '',
			tabs: [
				{
					name: '取名',
					icon: require('../assets/icon_quming_n/icon_quming_n@2x.png'),
					icon_active: require('../assets/icon_quming_s/icon_quming_s@2x.png'),
					path: '/'
				},
				{
					name: '运势',
					icon: require('../assets/icon_yunshi_n/icon_yunshi_n@2x.png'),
					icon_active: require('../assets/icon_yunshi_s/icon_yunshi_s@2x.png'),
					path: '/lucky'
				},
				{
					name: '我的',
					icon: require('../assets/icon_wode_n/icon_wode_n@2x.png'),
					icon_active: require('../assets/icon_wode_s/icon_wode_s@2x.png'),
					path: '/user'
				}
			]
		}
	},
	watch: {
		$route(to) {
			this.matchRouter(to.name)
		}
	},
	mounted() {
		this.matchRouter(this.$route.name)
	},
	methods: {
		handleItem() {
			this.$router.replace(this.tabs[this.active].path, () => {})
		},
		tabChange() {
			console.log(this.active)
		},
		matchRouter(name) {
			if (/^lucky/i.test(name)) {
				this.active = 1
			} else if (/home/i.test(name) || /^score/i.test(name) || /^detail/i.test(name)) {
				this.active = 0
			} else if (/user/i.test(name)) {
				this.active = 2
			}
		}
	}
}
</script>

<style lang="less">
	.van-tabbar-item__icon {
		margin-bottom: 0;
	} 
	.van-tabbar-item__icon img {
		height: 28px;
	}
	.van-tabbar-item {
		font-size: 10px;
	}
</style>