import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nameDetail: {
      free_names: [],
      analysis_list: {
        xing_zuo: {
          advantage: '',
          shortcoming: '',
          name: '',
          english_name: '',
          text: ''
        },
        sheng_xiao: {
          advantage: '',
          shortcoming: '',
          name: '',
          text: ''
        },
        guo_xue: {
          ba_zi: [],
          gong_li: {},
          na_yin: [],
          nong_li: {},
          text: '',
          wu_xing: []
        },
        wu_xing: {
          ratio: {},
          text2: '',
          text3: ''
        },
        like_use_god: {
          diff: '',
          same: '',
          total: ''
        }
      },
      score_list: {
        guo_xue: '',
        ren_ru_qi_ming: '',
        san_cai_wu_ge: '',
        sheng_xiao: '',
        xin_li_xue: '',
        xing_zhuo: '',
        yin_xing_yi: '',
        zhou_yi: '',
        zong_he: ''
      }
    },
    freeName: {
      xing: '',
      ming: ''
    },
    showLogin: false
  },
  mutations: {
    CHANGE_NAME_DETAIL (state, {data={}}) {
      state.nameDetail = data
    },
    CHANGE_FREE_NAME (state, {data={}}) {
      state.freeName = data
    },
    CHANGE_LOGIN (state, isShow) {
      state.showLogin = isShow
    }
  },
  actions: {
  },
  modules: {
  }
})
