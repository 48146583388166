<template>
	<div>
		<div class="wuxing" v-if="analy.wu_xing">
			<div v-for="(item, key) in analy.wu_xing.ratio" :class="`wuxing_${$wuxingmap[key]}`" :key="key" class="wuxing_row flex">
				<div class="wuxing_col1">{{key}}</div>
				<div class="wuxing_col2">
					<div :style="{width: item + '%'}"></div>
				</div>
				<div class="wuxing_col3">{{item + '%'}}</div>
			</div>
		</div>
		<div class="geo-hilight-box">
			<div class="geo-hilight-box-title">{{analy.wu_xing.text2}}</div>
			<div class="geo-hilight-box-text">{{analy.wu_xing.text3}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WuxingCard',
	props: {
		analy: {
			type: Object,
			default: function() {
				return {
					wuxing: {
						ratio: [],
						text2: '',
						text3: ''
					}
				}
			}
		}
	},
	data() {
		return {
			
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.wuxing {
	margin-bottom: 0.16rem;
	.wuxing_row + .wuxing_row {
		margin-top: 8px;
	}
	.wuxing_row {
		.wuxing_col1 {
			width: 0.26rem;
			height: 0.26rem;
			color: white;
			border-radius: 50%;
			text-align: center;
			line-height: 0.26rem;
			flex-shrink: 0;
		}
		.wuxing_col2 {
			width: (229% / 249 * 100);
			height: 0.16rem;
			border-radius: 0.08rem;
			background: #EEE8E8;
			margin: 0 8px 0 12px;
			position: relative;
			div {
				border-radius: 0.08rem;
				height: 0.16rem;
			}
		}
		.wuxing_col3 {
			flex-shrink: 0;
		}
	}
	.wuxing_jin {
		color: @jin;
		.wuxing_col1 {
			background: @jin;
		}
		.wuxing_col2 {
			div {
				background: @jin;
			}
		}
	}
	.wuxing_mu {
		color: @mu;
		.wuxing_col1 {
			background: @mu;
		}
		.wuxing_col2 {
			div {
				background: @mu;
			}
		}
	}
	.wuxing_shui {
		color: @shui;
		.wuxing_col1 {
			background: @shui;
		}
		.wuxing_col2 {
			div {
				background: @shui;
			}
		}
	}
	.wuxing_huo {
		color: @huo;
		.wuxing_col1 {
			background: @huo;
		}
		.wuxing_col2 {
			div {
				background: @huo;
			}
		}
	}
	.wuxing_tu {
		color: @tu;
		.wuxing_col1 {
			background: @tu;
		}
		.wuxing_col2 {
			div {
				background: @tu;
			}
		}
	}
}
</style>