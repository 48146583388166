<template>
	<div class="login-box">
		<div class="login border-box">
			<div class="login-title">请选择登录方式</div>
			<div class="login-ways">
				<div v-if="isWx()" class="login-wx-btn flex" @click="loginFromWX"><i class="icon icon-wx"></i>微信登录</div>
				<div class="login-qq-btn flex" @click="loginFromQQ"><i class="icon icon-qq"></i>QQ 登录</div>
				<div v-if="!isWx()" class="login-wx-btn flex" @click="shareWX"><i class="icon icon-wx"></i>微信分享</div>
				<div v-if="!isWx()" class="login-tip geo-margin-top">建议选择QQ登录，或分享登录链接至微信打开。</div>
			</div>
			<div class="login-intro">
				<p>登录即代表您已同意我们的</p>
				<p>《服务协议》和《隐私协议》</p>
			</div>
		</div>
		<!-- <div class="login-close" @click="close">
			<i class="icon icon-close"></i>
		</div> -->
	</div>
</template>

<script>
	import {isWx} from '../utils/utils'
	import { Loginqq } from '../api/User'
	import { Toast } from 'vant'
	export default {
		name: 'Login',
		data() {
			return {
				
			}
		},
		created() {

		},
		methods: {
			isWx,
			close() {
				this.$emit('close')
			},
			loginFromWX() {
				const redirect = encodeURI(window.location.href)
				window.location.href = 'https://zhouyi.tinywan.com/api/oauth/wechat?redirect=' + redirect
			},
			loginFromQQ() {
				const redirect = encodeURI(window.location.href)
				window.location.href = 'https://zhouyi.tinywan.com/api/oauth/qq?redirect=' + redirect
			},
			shareWX() {
				const clipboard_text = window.location.href
				this.$copyText(clipboard_text).then(function (e) {
          Toast({
						message: '网址链接已复制，请粘贴到微信内打开网页后再做分享～'
					})
        }, function (e) {
          console.log(e)
        })
			}
		}
	}
</script>

<style lang="less">
@import '../styles/var.less';
.login-box {
	.login {
		background: white;
		width: 2.8rem;
		height: 2.52;
		border-radius: 8px;
		padding: 0.24rem 0.24rem 0.16rem;
		&-title {
			text-align: center;
			color: @black;
			font-size: 0.18rem;
			font-weight: @fw500;
			line-height: 0.25rem;
		}
		&-wx-btn,
		&-qq-btn {
			width: 2.3rem;
			height: 0.42rem;
			border-radius: 4px;
			margin-top: 0.16rem;
			justify-content: center;
			.icon {
				margin-right: 10px;
			}
		}
		&-wx-btn {
			background: rgba(26, 188, 87, 0.2);
			border: 1px solid #1ABC57;
			color: #1ABC57;
		}
		&-qq-btn {
			background: rgba(49, 155, 226, 0.2);;
			border: 1px solid #319BE2;
			color: #319BE2;
		}
		&-intro {
			color: #BDBDBD;
			font-size: @f14;
			line-height: @lh20;
			text-align: center;
			margin-top: 0.16rem;
		}
		&-close {
			text-align: center;
			margin-top: 0.2rem;
			.icon {
				width: 0.3rem;
				height: 0.3rem;
			}
		}
	}
	.login-tip {
		font-size: 0.14rem;
		color: #666666;
		line-height: 0.2rem;
	}
}
</style>