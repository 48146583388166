<template>
	<div class="user-box border-box">
		<div class="header-box">
			<div class="avatar-box" @click="handleLoginShow">
				<div class="avatar">
					<img :src="avatar" alt="avatar">
					<div class="avatar-name">{{name}}</div>
				</div>
				<div v-if="isLogin" class="logout" @click.stop="logout">退出</div>
			</div>
			<div class="book-box border-box flex">
				<div class="book-left">
					<div class="tit1 flex">取名预约日期</div>
					<div class="tit2" v-if="isNoBooking">当前暂无预约消息</div>
					<div class="tit2" v-if="booking">{{booking.split(' ')[0]}} <span>{{booking.split(' ')[1]}}</span></div>
					<div class="tit3">给宝宝一个更好的开始</div>
				</div>
				<div class="book-right">
					<button :class="{booked: booking}" @click="gotoBook">{{booking ? '预约成功' : '立即预约'}}</button>
				</div>
			</div>
			
		</div>
		<div class="cards border-box">
			<div class="card-box border-box">
				<div class="tit-box border-box">常用服务</div>
				<div class="con-box flex border-box">
					<div class="app-box" @click="gotoHistory(item.url)" v-for="item in apps" :key="item.name">
						<img :src="item.icon" alt="">
						<div class="app-name">{{item.name}}</div>
					</div>
				</div>
			</div>
			<div class="card-box border-box master-box">
				<div class="tit-box border-box tit-box-center master-tit">联系大师</div>
				<div class="con-box flex border-box">
					<img class="master-qr" src="../assets/master-qr.jpg" alt="">
				</div>
				<div class="master-desc">
					<p>19位来自台湾、香港、新加坡等地资深易学专家，只为让您拥有最符合的名字！中国大陆和港澳台、东南亚上万用户的共同选择~</p>
				</div>
			</div>
		</div>
		<!-- <van-popup round close-on-popstate closeable v-model="showLogin">
			<div class="flex user-login-wrapper" @click.stop>
				<Login @close="showLogin = false"></Login>
			</div>
		</van-popup> -->
		<!-- <van-overlay :show="showLogin" @click="showLogin = false">
			<div class="flex user-login-wrapper" @click.stop>
				<Login @close="showLogin = false"></Login>
			</div>
		</van-overlay> -->
	</div>
</template>

<script>
	import Login from '../components/Login'
	import { getToken, getUserInfo, getBooking } from '../api/User'
import { Toast } from 'vant'
	export default {
		name: 'User',
		components: {
			Login
		},
		async created() {
			const { app_id, sign, showLogin } = this.$route.query

			this.showLogin = !!showLogin && !window.localStorage.getItem('access_token')

			this.$EventBus.$emit('showLogin', !!showLogin && !window.localStorage.getItem('access_token'))

			if (app_id && sign) {
				try {
					let res = await getToken({
						data: {
							app_id,
							sign
						}
					})
					if (res.code === 0) {
						window.localStorage.setItem('access_token', res.data.token_type + ' ' + res.data.access_token)
					}
				} catch(error) {
					this.resetStatus()
				}
			}

			this.getUserInfo()
		},
		methods: {
			resetStatus() {
				window.localStorage.removeItem('access_token')
				this.isLogin = false
				this.name = '立即登录'
				this.avatar = require('../assets/avatar_default.png')
				this.isNoBooking = true
				this.booking = ''
			},
			logout() {
				this.resetStatus()
			},
			gotoHistory(url) {
				this.$router.push(url)
			},
			gotoBook() {
				const token = window.localStorage.getItem('access_token')
				if (!token) {
					// return this.showLogin = true
					return this.$EventBus.$emit('showLogin', true)
				}
				if (this.booking) return
				this.$router.push('/user/booking?redirect=' + encodeURIComponent(this.$route.fullPath))
			},
			async getUserInfo() {
				try {
					const { code, data, msg } = await getUserInfo()
					
					if (code === 0) {
						this.isLogin = true
						this.name = data.username
						this.avatar = data.avatar
						const bookingData = await getBooking()
						if (bookingData.code === 0) {
							this.booking = bookingData.data.title
							this.isNoBooking = false
						} else {
							this.isNoBooking = true
							// Toast.fail(bookingData.msg)
						}
					} else {
						this.name = '立即登录'
					}
				} catch(error) {
					this.name = '立即登录'
				}
			},
			handleLoginShow() {
				const token = window.localStorage.getItem('access_token')
				if (!token) {
					// this.showLogin = true
					this.$EventBus.$emit('showLogin', true)
				}
			}
		},
		data() {
			return {
				isLogin: false,
				isNoBooking: false,
				booking: '',
				showLogin: false,
				avatar: require('../assets/avatar_default.png'),
				name: ' ',
				apps: [
					{
						icon: require('../assets/icon_star.png'),
						name: '名字收藏',
						url: '/user/name-collection'
					},
					{
						icon: require('../assets/icon_ana.png'),
						name: '历史测分',
						url: '/user/score-history'
					},
					{
						icon: require('../assets/icon_coment.png'),
						name: '历史评价',
						url: '/user/comment-history'
					},
					// {
					// 	icon: require('../assets/icon_lucky.png'),
					// 	name: '往期运势',
					// 	url: '/user/lucky-history'
					// }
				]
			}
		}
	}
</script>

<style lang="less">
@import '../styles/var.less';
.user-box {
	min-height: 100vh;
	background-color: @bgcolor;
	.header-box {
		.book-box {
			min-height: 1.06rem;
			padding: 0.18rem 0.28rem 0.16rem;
			background-color: white;
			.book-left {
				.tit1 {
					color: @gray;
					font-size: @f14;
					line-height: @lh20;
					font-weight: @fw400;
					justify-content: start;
					&::before {
						content: '';
						display: inline-block;
						background-image: url('../assets/icon_clock.png');
						background-position: center;
						background-size: contain;
						background-repeat: no-repeat;
						width: 0.16rem;
						height: 0.16rem;
						margin-right: 2px;
					}
				}
				.tit2 {
					color: @black;
					font-size: @f16;
					line-height: @lh;
					font-weight: @fw500;
					margin: 0.08rem 0;
					span {
						font-size: 0.12rem;
						font-weight: normal;
					}
				}
				.tit3 {
					color: @black666;
					font-size: @f12;
					line-height: 0.17rem;
					font-weight: @fw400;
				}
			}
			.book-right {
				button {
					width: 1rem;
					height: 0.34rem;
					background-color: @btn-red;
					border-radius: 0.17rem;
					font-size: 0.16rem;
					box-shadow: 0px 4px 8px 0px rgba(237, 72, 72, 0.3);
				}
				button.booked {
					width: 1.1rem;
					background: #42CF80;
					box-shadow: 0px 4px 8px 0px rgba(66, 207, 128, 0.56);
					display: flex;
					align-items: center;
					justify-content: center;
					&::before {
						content: '';
						display: block;
						width: 0.18rem;
						height: 0.18rem;
						background-image: url('../assets/icon_book_success.png');
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						margin-right: 4px;
					}
				}
			}
		}
	}
	.cards {
		padding: 0.12rem 0.16rem;
		.card-box + .card-box {
			margin-top: 0.12rem;
		}
		.card-box {
			.app-box {
				img {
					width: 0.4rem;
					height: 0.4rem;
					display: block;
					margin: 0 auto;
				}
				.app-name {
					color: @black;
					font-size: 0.12rem;
					line-height: 0.17;
					margin-top: 5px;
				}
			}
			.master-tit {
				display: flex;
				align-items: center;
				justify-content: center;
				&::after,
				&::before {
					content: '';
					display: block;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
					width: 0.2rem;
					height: 0.16rem;
				}
				&::before {
					background-image: url('../assets/icon_circle_g_1.png');
					margin-right: 8px;
				}
				&::after {
					background-image: url('../assets/icon_circle_g_2.png');
					margin-left: 8px;
				}
			}
			.master-qr {
				width: 0.98rem;
				height: 0.98rem;
				border: 3px solid #E0C3C3;
				display: block;
				margin: 0 auto;
			}
		}
	}
}
.avatar-box {
	position: relative;
	width: 100%;
	height: 1.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: linear-gradient(to right, #FEC19B, #F47547, #FC3E3E);
	.avatar {
		img {
			display: block;
			width: 0.58rem;
			height: 0.58rem;
			border-radius: 50%;
			border: 2px white solid;
			margin: 0 auto;
		}
		&-name {
			margin-top: 8px;
			color: white;
			font-size: 0.16rem;
			line-height: 0.22rem;
			text-align: center;
			width: (0.16rem * 16);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	.logout {
		color: white;
		border: 1px solid white;
		border-radius: 0.14rem;
		height: 0.24rem;
		font-size: 0.14rem;
		line-height: 0.24rem;
		display: flex;
		align-items: center;
		justify-items: center;
		padding: 0 8px;
		position: absolute;
		top: 0.1rem;
		right: 0.16rem;
		&::after {
			content: '';
			display: block;
			width: 0.12rem;
			height: 0.12rem;
			background-image: url('../assets/icon_logout.png');
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			margin-left: 4px;
		}
	}
}
.user-box .user-login-wrapper {
	width: 100%;
	height: 100%;
	justify-content: center;
}
.bebug-logout {
	position: absolute !important;
	top: 12px;
	right: 16px;
}
.master-desc {
	font-size: 0.14rem;
	line-height: 0.22rem;
	color: #666666;
	box-sizing: border-box;
	padding: 0.08rem;
}
.card-box.master-box {
	background-image: url('../assets/master-bg.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: 0 0.4rem;
}
</style>