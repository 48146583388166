<template>
	<v-chart :options="optionData" :init-options="initOptions"></v-chart>
</template>

<script>
import ECharts from 'vue-echarts'
import * as echarts from 'echarts/dist/echarts.js'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/title'

export default {
	name: 'CircleBase',
	components: {
		'v-chart': ECharts
	},
	props: {
		title: {
			type: Object,
			default: function() {return {}}
		},
		barWidth: Number,
		color: String,
		tcolor: String,
		score: {
			type: Number,
			default: 0
		},
		radius: Number,
		data: {
			type: Array,
			default: function() {
				return []
			}
		},
		colors: {
			type: Array,
			default: function() {
				return []
			}
		}
	},
	computed: {
		optionData() {
			this.options.polar.radius = this.radius
			this.options.series[0].data[0].itemStyle.normal.color = this.color
			this.options.series[0].data[0].value = this.score
			this.options.series[0].barWidth = this.options.series[1].barWidth = this.barWidth
			this.options.title = this.title
			return this.options
		}
	},
	data() {
		return {
			initOptions: {
        renderer: 'canvas'
      },
			circleData: {},
			options: {
				animation: false,
				angleAxis: {
					max: 100, // 满分
					clockwise: true, // 逆时针
					// 隐藏刻度线
					axisLine: {
						show: false
					},
					axisTick: {
						show: false
					},
					axisLabel: {
						show: false
					},
					splitLine: {
						show: false
					}
				},
				radiusAxis: {
					type: 'category',
					// 隐藏刻度线
					axisLine: {
						show: false
					},
					axisTick: {
						show: false
					},
					axisLabel: {
						show: false
					},
					splitLine: {
						show: false
					}
				},
				polar: {
					center: ['50%', '50%'],
					radius: '60' // 图形大小
				},
				series: [
					{
						type: 'bar',
						data: [{
							name: '',
							value: 0,
							itemStyle: {
								normal: {
									color: '#EA7373'
								}
							},
						}],
						coordinateSystem: 'polar',
						roundCap: true,
						barWidth: 8,
						barGap: '-100%', // 两环重叠
						z: 2,
					},
					{ // 灰色环
						type: 'bar',
						data: [{
							value: 100,
							itemStyle: {
								color: '#e2e2e2'
							}
						}],
						coordinateSystem: 'polar',
						roundCap: true,
						barWidth: 8,
						barGap: '-100%', // 两环重叠
						z: 1
					}
				]
			}
		}
	}
}
</script>

<style lang="less">
.echarts {
	width: 100%;
	height: 100%;
}
</style>

<style lang="less" scoped>

</style>