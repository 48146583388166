<template>
	<div class="geo-lucky-container border-box">
		<div :class="{'geo-lucky-wrap-qr': isQr}">
			<div class="geo-lucky-wrap" ref="card">
				<div :class="{qr: isQr}" class="geo-lucky-card">
					<div class="geo-lucky-card-title">穿衣</div>
					<div class="geo-lucky-card-subtitle">{{luckyDetail.title}} 五行穿衣</div>
					<div class="geo-list-box">
						<div v-for="(item, index) in dressing" :key="item[0]" class="geo-list-row flex">
							<div class="geo-list-col1 geo-lucky-list-col1" :style="{color: dressMap[item[0]].color}">{{dressMap[item[0]].name}}</div>
							<div class="geo-list-col2 geo-text-black666 geo-padding-bottom" :class="{'geo-border-bottom': index !== dressing.length - 1 }">
								{{item[1]}}
							</div>
						</div>
					</div>
					<div class="geo-line"></div>
					<div class="geo-lucky-card-title geo-lucky-card-title2">运势</div>
					<div class="geo-lucky-card-subtitle">{{luckyDetail.title}} 十二生肖每日运程</div>
					<div class="geo-list-box">
						<div v-for="(item, index) in fortune" :key="item[0]" class="geo-list-row flex">
							<div class="geo-list-col1 geo-lucky-list-col1">
								<img :src="require(`../assets/${item[0]}.png`)" :alt="item[0]">
							</div>
							<div class="geo-list-col2 geo-text-black666 geo-padding-bottom" :class="{'geo-border-bottom': index !== fortune.length - 1 }">
								{{item[1]}}
							</div>
						</div>
					</div>
				</div>
				<div v-if="isQr" class="share-box">
					<img class="desc" src="../assets/share-desc.png" alt="">
					<div class="qr-box">
						<img class="qr" src="../assets/qrcode.png" alt="">
						<div class="qr-tip">微信扫一扫</div>
					</div>
				</div>
			</div>
		</div>
		<div class="lucky-btn-box geo-margin-top">
			<div class="geo-btn yellow full">
				<div class="geo-btn-bg" @click="showQr">记录今日运势</div>
			</div>
		</div>
		<van-popup v-model="showShare" round position="bottom" :overlay="false">
			<div class="geo-share-box">
				<div class="geo-share-tit">立即分享给好友</div>
				<div class="geo-share-item-box">
					<div @click="handleShareItem(item.icon)" class="geo-share-item" v-for="item in shareOptions" :key="item.icon">
						<div class="geo-share-icon" :class="item.icon"></div>
						<div class="geo-share-desc">{{item.name}}</div>
					</div>
				</div>
				<div class="geo-share-cancel" @click="closeShare">取消</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import { ImagePreview, ShareSheet, Toast } from 'vant'
import html2canvas from 'html2canvas'
import { getLuckyDetail } from '../api/Main'
import { isWx, getDateString } from '../utils/utils'
var weekday = new Array(7);
weekday[0] = "星期日";
weekday[1] = "星期一";
weekday[2] = "星期二";
weekday[3] = "星期三";
weekday[4] = "星期四";
weekday[5] = "星期五";
weekday[6] = "星期六";
const date = new Date()
const year = date.getFullYear()
const month = ((date.getMonth() + 1) > 9) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))
const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
const week = weekday[date.getDay()]

const dressMap = {
	'ji': {
		name: '吉穿',
		color: '#EA7373'
	},
	'ci_ji': {
		name: '次穿',
		color: '#EA7373'
	},
	'bu_yi': {
		name: '不宜',
		color: '#6081B3'
	},
}

export default {
	name: 'LuckyDetail',
	data() {
		return {
			clipboard_text: 'https://www.mingzbaby.com/lucky/detail?date=' + getDateString(new Date()),
			showShare: false,
			shareOptions: [
				{ name: '微信好友', icon: 'wx' },
        { name: '朋友圈', icon: 'wxf' },
        { name: '保存图片', icon: 'download' }
			],
			dressMap,
			year,
			month,
			day,
			week,
			luckyDetail: {
				dressing: {},
				fortune: {}
			},
			htmlUrl: '',
			isQr: false
		}
	},
	computed: {
		dressing() {
			return Object.entries(this.luckyDetail.dressing)
		},
		fortune() {
			return Object.entries(this.luckyDetail.fortune)
		}
	},
	created() {
		this.getLucky()
	},
	methods: {
		handleShareItem(id) {
			if (id === 'download') {
				let elea = document.createElement('a')
				elea.href = this.htmlUrl
				elea.download = 'lucky' + new Date().getTime()
				elea.click()
			} else {
				this.$copyText(this.clipboard_text).then(function (e) {
          Toast({
						message: '网址链接已复制，请粘贴到微信内打开网页后再做分享～'
					})
        }, function (e) {
          console.log(e)
        })
			}
		},
		closeShare() {
			// this.showShare = false
			// this.isQr = false
			// console.log(this.imagePreview)
			this.imagePreview.close()
		},
		onShareSelect(option, index) {
			console.log(option, index)
		},
		async getLucky() {
			const query = this.$route.query
			const {code, data} = await getLuckyDetail({
				params: query
			})
			if (code === 0) {
				console.log(data)
				this.luckyDetail = data
			}
		},
		showQr() {
			this.isQr = true
			this.$nextTick(() => {
				this.h2c()
			})
		},
		h2c() {
      window.pageYOffset = 0;
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      const ele = this.$refs.card
      html2canvas(ele, {
        scale: 4,
        // backgroundColor: null
      }).then(_canvas => {
          let context = _canvas.getContext('2d')
          context.mozImageSmoothingEnabled = false
          context.webkitImageSmoothingEnabled = false
          context.msImageSmoothingEnabled = false
          context.imageSmoothingEnabled = false
          const hdr = _canvas.toDataURL('image/png', 1.0)
          if (hdr) {
						this.htmlUrl = hdr
						this.imagePreview = ImagePreview({
							images: [
								hdr
							],
							showIndex: false,
							closeable: isWx(),
							className: 'lucky-share-image-preview',
							closeIcon: require('../assets/lucky_share_tip.png'),
							onClose: () => {
								console.log('关闭')
								this.isQr = false
								this.showShare = false
							},
						})
						if (isWx()) {
							Toast({
								message: '长按保存图片',
								icon: require('../assets/download.png'),
							})
						} else {
							this.showShare = true
						}
          }
        })
    }
	}
}
</script>

<style lang="less">
.lucky-share-image-preview {
	.van-icon__image {
		width: 0.68rem;
		height: 1.03rem;
	}
}
</style>

<style lang="less" scoped>
.geo-lucky-container {
	min-height: calc(100vh - 50px);
	width: 100%;
	background: #850D09;
	padding: 0.16rem;
	/deep/ .van-popup {
		z-index: 99999999 !important;
	}
	.geo-lucky-wrap {
		position: relative;
	}
	.geo-lucky-wrap-qr {
		border: 2px solid #EC9B28;
		box-shadow: inset 0px 0px 20px 0px #EC9B28;
		box-sizing: border-box;
		border-radius: 8px;
		overflow: hidden;
		background: white;
	}
	.share-box {
		width: 100%;
		height: 0.88rem;
		background: #B31414;
		margin-top: 0.36rem;
		box-sizing: border-box;
		padding: 8px 0.3rem;
		position: absolute;
		left: 0;
		bottom: 0.24rem;
		box-shadow: 0px 2px 6px 0px #B31414;
		img.desc {
			width: 1.58rem;
			height: 0.64rem;
		}
		.qr-box {
			position: absolute;
			right: 0.32rem;
			top: -0.4rem;
			border: 3px solid #B31414;
			img.qr {
				width: 0.8rem;
				height: 0.8rem;
			}
			.qr-tip {
				color: white;
				margin-top: 4px;
				line-height: 0.2rem;
				font-size: 0.14rem;
				text-align: center;
			}
		}
	}
	.geo-lucky-card {
		background: white;
		padding: 0.16rem;
		box-sizing: border-box;
		width: 100%;
		min-height: 5.21rem;
		border: 2px solid #EC9B28;
		box-shadow: inset 0px 0px 20px 0px #EC9B28;
		box-sizing: border-box;
		border-radius: 8px;
		&.qr {
			padding-bottom: 1.5rem;
			border: none;
			border-radius: 0;
			box-shadow: none;
			background: none;
		}
		.geo-lucky-card-title {
			font-size: 0.18rem;
			text-align: center;
			width: 100%;
			color: #D47E2A;
			line-height: 0.25rem;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 800;
			&::before, &::after {
				content: '';
				display: block;
				height: 0.0845rem;
				width: 1.28rem;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
			&::before {
				background-image: url('../assets/title_line_pre.png');
			}
			&::after {
				background-image: url('../assets/title_line_after.png');
			}
		}
		.geo-lucky-card-title2 {
			margin-top: 0.24rem;
		}
		.geo-lucky-card-subtitle {
			font-size: 0.14rem;
			color: #333;
			line-height: 0.2rem;
			margin: 0.24rem 0;
		}
		.geo-lucky-list-col1 {
			width: 0.48rem;
			flex-shrink: 0;
			img {
				width: 0.4rem;
			}
		}
	}
	.lucky-btn-box {
		padding: 0 0.16rem;
		width: 100%;
		box-sizing: border-box;
	}
}
</style>