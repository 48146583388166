<template>
	<div class="geo-comment border-box" :class="theme">
		<div class="geo-comment-header flex">
			<span class="geo-comment-header-title">客户评价</span>
			<van-button @click="showComment = true" class="geo-comment-header-btn" hairline icon="plus" round>评价一下</van-button>
		</div>
		<div class="geo-comment-content">
			<ul>
				<li v-for="(item, index) in list" :key="index">
					<div class="geo-comment-content-card border-box">
						<div class="geo-comment-content-card-header flex">
							<div class="geo-comment-content-card-author flex">
								<div class="geo-comment-content-card-author-avatar" :style="{backgroundImage: `url('${item.avatar}')`}"></div>
								<div class="geo-comment-content-card-author-name geo-one-ellipsis">{{item.nickname}}</div>
							</div>
							<div class="geo-comment-content-card-stars flex">
								<van-icon v-for="star in [1, 2, 3, 4, 5]" :name="(star <= item.star) ? 'star' : 'star-o'" :key="star" />
							</div>
						</div>
						<div class="geo-comment-content-card-detail" v-html="item.content"></div>
					</div>
					<div class="geo-line" v-if="!theme && (index + 1 < list.length)"></div>
				</li>
			</ul>
		</div>
		<div v-observe-visibility="visibilityChanged" class="more-box" v-if="theme === 'more' && list.length">{{moreText}}</div>
		<van-popup :lock-scroll="false" close-icon-position="top-left" closeable v-model="showComment" round position="bottom" :style="{ minHeight: '30%' }">
      <EditComent @close="showComment = false"></EditComent>
    </van-popup>
	</div>
</template>

<script>
import EditComent from './EditComment'
import { getComment } from '../api/Main'
export default {
	name: 'Comment',
	props: ['theme'],
	components: {
		EditComent
	},
	data() {
		return {
			list: [],
			showComment: false,
			moreText: '加载更多...',
			lock: false,
			hasMore: true,
			page: {
				page: 1,
				per_page: 6
			}
		}
	},
	created() {
		if (this.theme === 'more') {
			this.initMore()
		} else {
			this.init()
		}
	},
	methods: {
		visibilityChanged(isVisible) {
			console.log('isVisible', isVisible)
			if (isVisible && !this.lock && this.hasMore) {
				this.initMore()
			}
		},
		async init() {
			const {code, data, msg} = await getComment()
			if (code === 0) {
				this.list = data.list
			}
		},
		async initMore() {
			this.lock = true
			try {
				const {code, data, msg} = await getComment({
					params: this.page
				})
				if (code === 0) {
					this.page.page++
					this.hasMore = !(data.list.length < this.page.per_page)
					if (!this.hasMore) {
						this.moreText = '评价到底了~'
					}
					this.list = [...this.list, ...data.list]
					this.lock = false
				} else {
					this.lock = false
				}
			} catch (error) {
				this.lock = false
			}
		}
	}
}
</script>

<style lang="less">
@import '../styles/var.less';
.geo-comment {
	width: 100%;
	padding: 0.16rem;
	margin-top: 0.24rem;
	.geo-comment-header-btn {
		width: 0.78rem;
		height: 0.24rem;
		font-size: 0.12rem;
		flex-shrink: 0;
		color: @red;
		border-color: @red;
		padding: 0;
		.van-button__icon {
			font-size: 0.1rem;
		}
		.van-button__icon+.van-button__text, .van-button__loading+.van-button__text, .van-button__text+.van-button__icon, .van-button__text+.van-button__loading {
			margin-left: 3px;
		}
	}
	&-header {
		height: 0.4rem;
		&-title {
			font-size: 0.18rem;
			font-weight: @fw500;
			color: @black;
			line-height: 0.25rem;
		}
	}
	&-content {
		margin-top: 0.16rem;
		li {
			padding: 0 0.12rem;
		}
		&-card {
			padding: 0.16rem 0;
			&-author {
				&-avatar {
					width: 0.26rem;
					height: 0.26rem;
					border-radius: 50%;
					overflow: hidden;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					background-color: @bgcolor;
					margin-right: 4px;
				}
				&-name {
					color: @black;
					font-size: @f14;
					line-height: @lh20;
					font-weight: @fw500;
					width: (0.14rem * 10)
				}
			}
			&-stars {
				i + i {
					margin-left: 5px;
				}
				i {
					color: @btn-red;
				}
			}
			&-detail {
				margin-top: 0.08rem;
				color: @black;
				font-size: @f14;
				line-height: @lh20;
			}
		}
	}
}
.geo-comment.more {
	margin-top: 0;
	padding: 0;
	.geo-comment-header {
		padding: 0.16rem 0.16rem 0;
	}
	.geo-comment-content {
		li {
			padding: 0 0.24rem;
			background-color: white;
		}
		li + li {
			margin-top: 8px;
		}
	}
	.more-box {
		height: 0.64rem;
		font-size: 0.12rem;
		color: #969799;
		text-align: center;
		line-height: 0.64rem;
	}
}
</style>