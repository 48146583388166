<template>
	<div class="more-coment-container">
		<Comment @show="showComment = true" theme="more"></Comment>
	</div>
</template>

<script>
import Comment from '../components/Comment'
export default {
	name: '',
	data() {
		return {
			showComment: false
		}
	},
	components: {
		Comment
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.more-coment-container {
	min-height: 100vh;
	background-color: @bgcolor;
}
</style>