<template>
	<div class="user-book-container">
		<div class="user-book-form-box">
			<div class="user-book-form-box-title">必填信息</div>
			<van-form>
				<van-field
					input-align="right"
					v-model="formData.surname"
					name="宝宝姓氏"
					placeholder="请输入姓氏"
					:rules="[{ required: true }]">
					<template #label>
						<div class="user-book-label-box">
							<div class="user-book-label-box">宝宝姓氏</div>
							<i class="geo-icon">*</i>
						</div>
					</template>
				</van-field>
				<van-field
					right-icon="arrow"
					readonly
					input-align="right"
					clickable
					name="picker"
					:value="formData.birth_status"
					label="出生状态"
					placeholder="请选择出生状态"
					@click="showBirthPicker = true"/>
				<van-popup v-model="showBirthPicker" round position="bottom">
					<van-picker
						show-toolbar
						:columns="birthColumns"
						@confirm="onBirthConfirm"
						@cancel="showBirthPicker = false" />
				</van-popup>
				<van-field
					right-icon="arrow"
					readonly
					input-align="right"
					clickable
					name="picker"
					:value="formData.sex"
					label="性别"
					placeholder="请选择性别"
					@click="showSexPicker = true"/>
				<van-popup round v-model="showSexPicker" position="bottom">
					<van-picker
						show-toolbar
						:columns="sexColumns"
						:default-index="0"
						@confirm="onSexConfirm"
						@cancel="showSexPicker = false" />
				</van-popup>
				<van-field
					right-icon="arrow"
					readonly
					input-align="right"
					clickable
					name="calendar">
					<template #input>
						<DatePicker
							id="geo-booking"
							ref="bookPicker"
							@change="handleBookingDateChange"
							:placeholder="`请选择${birthDateLabel}`"
							dateid="bookingdate"
							hourid="bookinghour"
							minuteid="bookingminute"
							:min="minDate"
							:max="maxDate"></DatePicker>
					</template>
					<template #label>
						<div class="user-book-label-box">
							<div class="user-book-label-box">{{birthDateLabel}}</div>
							<i class="geo-icon">*</i>
						</div>
					</template>
				</van-field>
				<van-popup round v-model="showBirthSheet" position="bottom">
					<van-datetime-picker
						@cancel="showBirthSheet = false"
						@confirm="onCanConfirm"
						type="datetime"
						v-model="currentDate"
						title="选择完整时间"
						:formatter="dateFormater"
						:min-date="minDate"
						:max-date="maxDate" />
				</van-popup>
				<van-field
					right-icon="arrow"
					readonly
					input-align="right"
					clickable
					name="birth_place"
					v-model="formData.birth_place"
					label="出生地点"
					placeholder="请输入出生地点"
					@click="showArea = true">
					<template #label>
						<div class="user-book-label-box">
							<div class="user-book-label-box">出生地点</div>
							<i class="geo-icon">*</i>
						</div>
					</template>
				</van-field>
				<van-popup round v-model:show="showArea" position="bottom">
					<van-area
						:area-list="areaList"
						@confirm="onPlaceConfirm"
						@cancel="showArea = false"
					/>
				</van-popup>
				<!-- <div style="margin: 16px;">
					<van-button round block type="info" native-type="submit">提交</van-button>
				</div> -->
			</van-form>
		</div>
		<div class="user-book-form-box">
			<div class="user-book-form-box-title">预约信息</div>
			<van-form>
				<van-field
					input-align="right"
					v-model="formData.phone"
					name="手机号"
					label="手机号"
					placeholder="请输入手机号">
					<template #label>
						<div class="user-book-label-box">
							<div class="user-book-label-box">手机号</div>
							<i class="geo-icon">*</i>
						</div>
					</template>
				</van-field>
				<van-field
					right-icon="arrow"
					readonly
					input-align="right"
					clickable
					name="calendar"
					v-model="formData.reserve_time"
					label="预约时间"
					placeholder="请选择预约的时间"
					@click="showBookSheet = true">
					<template #label>
						<div class="user-book-label-box">
							<div class="user-book-label-box">预约日期</div>
							<i class="geo-icon">*</i>
						</div>
					</template>
				</van-field>
				<van-popup round v-model="showBookSheet" position="bottom">
					<van-datetime-picker
						@cancel="showBookSheet = false"
						@confirm="onBookingConfirm"
						type="date"
						v-model="currentDate"
						title="选择完整时间"
						:formatter="dateFormater"
						:min-date="minBookDate"
						:max-date="maxBookDate" />
				</van-popup>
			</van-form>
		</div>
		<div class="user-book-agree-box">
			<van-checkbox v-model="agree" :icon-size="16" checked-color="#ED4848">
				<div class="user-book-agree-item">已阅读并同意<router-link to="/statement">《隐私保护申明》</router-link></div>
			</van-checkbox>
		</div>
		<div class="user-book-btn-box">
			<van-button @click="submit" color="#ED4848" round size="large" :disabled="!agree">确认</van-button>
		</div>
	</div>
</template>

<script>
import DatePicker from '../components/datepicker'
import areaList from '../utils/area.js'
import { addBooking } from '../api/User'
import { Toast } from 'vant'
import { formatNumberWith, dateFormater, formatBirthLabel } from '../utils/utils'
import dayjs from 'dayjs'
export default {
	name: 'UserBook',
	components: {
		DatePicker
	},
	data() {
		return {
			areaList,
			formData: {
				surname: '',
				birth_status: '',
				sex: '',
				birth_year: '',
        birth_month: '',
        birth_day: '',
        birth_hour: '',
        birth_minute: '',
				date: '',
				reserve_time: '',
				phone: '',
				birth_place: '',
				is_lunar: ''
			},
			agree: false,
			showBirthPicker: false,
			birthColumns: ['已出生', '未出生'],
			showSexPicker: false,
			sexColumns: ['男', '女'],
			showBirthSheet: false,
			minDate: 1940,
			maxDate: 2030,
			currentDate: new Date(),
			minBookDate: new Date(),
			maxBookDate: new Date(dayjs(new Date()).add(14, 'day')),
			showArea: false,
			showBookSheet: false
		}
	},
	computed: {
    birthDateLabel() {
      return formatBirthLabel(this.formData.birth_status)
    }
  },
	methods: {
		handleBookingDateChange(d) {
			this.formData.date = d.text
      this.formData.birth_year = d.y
      this.formData.birth_month = d.m
      this.formData.birth_day = d.d
      this.formData.birth_hour = d.h
      this.formData.birth_minute = d.i
      this.formData.is_lunar = d.isLunar
		},
		dateFormater,
		handleBirthChange(e) {
			// this.resetDate()
			// if (e === '已出生') {
			// 	this.minDate = 1940
      //   this.maxDate = new Date().getFullYear()
			// } else {
			// 	this.minDate = new Date().getFullYear()
			// 	this.maxDate = 2030
			// }
		},
		resetDate() {
			this.$refs.bookPicker.clear()
			this.formData.date = ''
			this.formData.birth_year = ''
			this.formData.birth_month = ''
			this.formData.birth_day = ''
			this.formData.birth_hour = ''
			this.formData.birth_minute = ''
			this.formData.is_lunar = ''
    },
		async submit() {
			this.formData.surname = this.formData.surname.trim()

			let options = JSON.parse(JSON.stringify(this.formData))
			
			options.birth_status && (options.birth_status = options.birth_status === '已出生' ? 1 : 2)
			options.sex && (options.sex = options.sex === '男' ? 1 : 2)

			const {data, msg} = await addBooking({
				data: options
			})
			Toast.success(msg)
			this.$route.query.redirect && this.$router.push(this.$route.query.redirect)
		},
		onBirthConfirm(e) {
			console.log(e)
			this.handleBirthChange(e)
			this.formData.birth_status = e
			this.showBirthPicker = false
		},
		onSexConfirm(e) {
			console.log(e)
			this.formData.sex = e
			this.showSexPicker = false
		},
		onCanConfirm (date) {
      this.formData.birth_year = '' + date.getFullYear()
      this.formData.birth_month = '' + (date.getMonth() + 1)
      this.formData.birth_day = '' + date.getDate()
      this.formData.birth_hour = '' + date.getHours()
      this.formData.birth_minute = '' + date.getMinutes()

      this.formData.date = `${date.getFullYear()}/${formatNumberWith(date.getMonth() + 1)}/${formatNumberWith(date.getDate())} ${formatNumberWith(date.getHours())}:${formatNumberWith(date.getMinutes())}`
      this.showBirthSheet = false
		},
		onPlaceConfirm (values) {
      this.showArea = false
      this.formData.birth_place = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('-')
		},
		onBookingConfirm (date) {
      this.formData.reserve_time = `${date.getFullYear()}-${formatNumberWith(date.getMonth() + 1)}-${formatNumberWith(date.getDate())}`
      this.showBookSheet = false
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.user-book-container {
	height: 100vh;
	background-color: @bgcolor;
	.user-book-form-box {
		.user-book-form-box-title {
			font-size: 0.16rem;
			line-height: 0.5rem;
			color: @black;
			font-weight: 500;
			box-sizing: border-box;
			padding: 0 0.16rem;
		}
	}
}
.user-book-agree-box {
	padding: 0 0.16rem;
	margin-top: 0.2rem;
	font-size: 0.14rem;
	color: #666;
	.user-book-agree-item {
		color: #666;
	}
	a {
		color: @red;
	}
}
.user-book-btn-box {
	padding: 0 0.16rem;
	margin-top: 0.22rem;
	button {
		height: 0.44rem;
	}
}
.user-book-label-box {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	.geo-icon {
		color: red;
	}
	.icon-info {
		width: 0.16rem;
		height: 0.16rem;
		min-width: 0.16rem;
		min-height: 0.16rem;
		display: inline-block;
		margin-left: 4px;
	}
}
/deep/ #geo-booking {
	text-align: right;
}
</style>