import { getToken } from '../api/User'
export default async function handleLogin (vm) {
	const { app_id, sign, showLogin } = vm.$route.query
	// const token = window.localStorage.getItem('access_token')
	if (app_id && sign) {
		let res = await getToken({
			data: {
				app_id,
				sign
			}
		})
		if (res.code === 0) {
			window.localStorage.setItem('access_token', res.data.token_type + ' ' + res.data.access_token)
		}
	}
}