<template>
	<div>
		<Card theme="center" :title="title + '评分'">
			<div class="geo-detail-more-type-card-header">
				<div class="geo-detail-more-type-card-header-chart">
					<CircleChart :title="$circleTitle(score)" :score="score" color="#EA7373" :radius="100" :barWidth="8"></CircleChart>
				</div>
			</div>
			<ContentCard title="结构解析">
				<div class="geo-wuge-structure flex">
					<div class="left">外格 {{typeData.wai_ge && typeData.wai_ge.bi_hua}}</div>
					<div class="mid">
						<div class="mid-ele">1</div>
						<div class="mid-ele">{{nameData.xing}} {{typeData.bi_hua[0]}}</div>
						<div class="mid-ele">{{nameData.ming[0]}} {{typeData.bi_hua[1]}}</div>
						<div class="mid-ele">{{nameData.ming[1]}} {{typeData.bi_hua[2]}}</div>
						<div class="left-line-box"></div>
						<div class="right-line-box">
							<div class="right-line"></div>
							<div class="right-line"></div>
							<div class="right-line"></div>
						</div>
					</div>
					<div class="right">
						<div class="right-ele">天格 {{typeData.tian_ge && typeData.tian_ge.bi_hua}}</div>
						<div class="right-ele">人格 {{typeData.ren_ge && typeData.ren_ge.bi_hua}}</div>
						<div class="right-ele">地格 {{typeData.di_ge && typeData.di_ge.bi_hua}}</div>
					</div>
				</div>
				<div class="geo-line geo-line-red"></div>
				<div class="geo-wuge-structure-total">总格 {{typeData.zong_ge && typeData.zong_ge.bi_hua}}</div>
				<div class="geo-wuge-structure-detail">
					<div class="geo-wuge-structure-detail-ele" v-if="typeData.tian_ge">
						<div class="geo-wuge-structure-detail-ele-header">
							<div class="col1">
								<span class="tag1">天格</span>
								<span class="tag2">{{typeData.tian_ge.bi_hua}}</span>
							</div>
							<div class="col2" :class="xiongji[typeData.tian_ge.xiong_ji]">{{typeData.tian_ge.xiong_ji}}</div>
							<div class="col3">大成之数</div>
						</div>
						<div class="geo-wuge-structure-detail-ele-con">
							<div class="geo-wuge-structure-detail-ele-col1">解析</div>
							<div class="geo-wuge-structure-detail-ele-col2">
								<p>{{typeData.tian_ge.title}}</p>
								<div class="hilight" v-html="typeData.tian_ge.desc"></div>
							</div>
						</div>
					</div>
					<div class="geo-wuge-structure-detail-ele" v-if="typeData.di_ge">
						<div class="geo-wuge-structure-detail-ele-header">
							<div class="col1">
								<span class="tag1">地格</span>
								<span class="tag2">{{typeData.di_ge.bi_hua}}</span>
							</div>
							<div class="col2" :class="xiongji[typeData.di_ge.xiong_ji]">{{typeData.di_ge.xiong_ji}}</div>
							<div class="col3">大成之数</div>
						</div>
						<div class="geo-wuge-structure-detail-ele-con">
							<div class="geo-wuge-structure-detail-ele-col1">解析</div>
							<div class="geo-wuge-structure-detail-ele-col2">
								<p>{{typeData.di_ge.title}}</p>
								<div class="hilight" v-html="typeData.di_ge.desc"></div>
							</div>
						</div>
					</div>
					<div class="geo-wuge-structure-detail-ele" v-if="typeData.ren_ge">
						<div class="geo-wuge-structure-detail-ele-header">
							<div class="col1">
								<span class="tag1">人格</span>
								<span class="tag2">{{typeData.ren_ge.bi_hua}}</span>
							</div>
							<div class="col2" :class="xiongji[typeData.ren_ge.xiong_ji]">{{typeData.ren_ge.xiong_ji}}</div>
							<div class="col3">大成之数</div>
						</div>
						<div class="geo-wuge-structure-detail-ele-con">
							<div class="geo-wuge-structure-detail-ele-col1">解析</div>
							<div class="geo-wuge-structure-detail-ele-col2">
								<p>{{typeData.ren_ge.title}}</p>
								<div class="hilight" v-html="typeData.ren_ge.desc"></div>
							</div>
						</div>
					</div>
					<div class="geo-wuge-structure-detail-ele" v-if="typeData.wai_ge">
						<div class="geo-wuge-structure-detail-ele-header">
							<div class="col1">
								<span class="tag1">外格</span>
								<span class="tag2">{{typeData.wai_ge.bi_hua}}</span>
							</div>
							<div class="col2" :class="xiongji[typeData.wai_ge.xiong_ji]">{{typeData.wai_ge.xiong_ji}}</div>
							<div class="col3">大成之数</div>
						</div>
						<div class="geo-wuge-structure-detail-ele-con">
							<div class="geo-wuge-structure-detail-ele-col1">解析</div>
							<div class="geo-wuge-structure-detail-ele-col2">
								<p>{{typeData.wai_ge.title}}</p>
								<div class="hilight" v-html="typeData.wai_ge.desc"></div>
							</div>
						</div>
					</div>
					<div class="geo-wuge-structure-detail-ele" v-if="typeData.zong_ge">
						<div class="geo-wuge-structure-detail-ele-header">
							<div class="col1">
								<span class="tag1">总格</span>
								<span class="tag2">{{typeData.zong_ge.bi_hua}}</span>
							</div>
							<div class="col2" :class="xiongji[typeData.zong_ge.xiong_ji]">{{typeData.zong_ge.xiong_ji}}</div>
							<div class="col3">大成之数</div>
						</div>
						<div class="geo-wuge-structure-detail-ele-con">
							<div class="geo-wuge-structure-detail-ele-col1">解析</div>
							<div class="geo-wuge-structure-detail-ele-col2">
								<p>{{typeData.zong_ge.title}}</p>
								<div class="hilight">{{typeData.zong_ge.desc || ''}}</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="geo-line"></div>
				<div class="geo-wuge-others">
					<div class="geo-wuge-others-ele">
						<div class="tag">三才</div>
						<div class="info">虽有成功运，但其基础不稳，有眼前之灾祸，因过劳而致肺病，属急变急死的配置。</div>
					</div>
					<div class="geo-wuge-others-ele">
						<div class="tag">三才</div>
						<div class="info">虽有成功运，但其基础不稳，有眼前之灾祸，因过劳而致肺病，属急变急死的配置。</div>
					</div>
				</div> -->
			</ContentCard>
		</Card>
	</div>
</template>

<script>
import Card from '../Card'
import ContentCard from '../ContentCard'
import CircleChart from '../CircleChart'
import WuxingCard from '../WuxingCard'
import AnalyCard from '../AnalyCard' 
export default {
	name: 'WugeCard',
	props: {
		title: String,
		data: Object,
		score: Number || String
	},
	components: {
		Card,
		ContentCard,
		CircleChart,
		WuxingCard,
		AnalyCard
	},
	computed: {
		scoreList() {
			return this.data.score_list
		},
		typeData() {
			if (this.data.free_names[this.$route.query.index]) {
				return this.data.free_names[this.$route.query.index]['wu_ge']
			}
			return {}
		},
		scoreData() {
			return this.data.free_names[this.$route.query.index].score_table
		},
		nameData() {
			return this.data.free_names[this.$route.query.index]
		},
	},
	data() {
		return {
			xiongji: {
				'大吉': 'ji',
				'吉': 'ji',
				'半吉': 'banji',
				'凶': 'xiong',
				'大凶': 'xiong',
			}
		}
	},
	methods: {
	}
}
</script>

<style lang="less" scoped>
@import '../../styles/var.less';
@ji: @red;
@xiong: #E9CB61;
@banji: #559FFF;
.geo-wuge-structure {
	color: @black;
	margin-bottom: 0.16rem;
	.mid {
		width: 1.48rem;
		text-align: center;
		position: relative;
		.mid-ele + .mid-ele {
			margin-top: 0.16rem;
		}
		.left-line-box {
			position: absolute;
			left: 0;
			top: 0.1rem;
			border: 1px solid @red;
			border-right: none;
			width: 0.32rem;
			height: 1.08rem;
		}
		.right-line-box {
			position: absolute;
			right: 0;
			top: 0.1rem;
			width: 0.32rem;
			height: 1.08rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			.right-line {
				border: 1px solid @red;
				border-left: none;
				width: 0.32rem;
				height: 0.32rem;
			}
		}
	}
	.right {
		display: flex;
		flex-direction: column;
		justify-items: center;
		.right-ele + .right-ele {
			margin-top: 0.18rem;
		}
	}
}
.geo-wuge-structure-total {
	text-align: center;
	color: @black;
	margin-top: 0.16rem;
}
.geo-wuge-structure-detail {
	margin-top: 0.32rem;
	.geo-wuge-structure-detail-ele-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.col1 {
			line-height: 0.22rem;
			font-weight: @fw500;
			.tag1 {
				color: @black;
			}
			.tag2 {
				color: #EA7373;
				padding-left: 8px;
			}
		}
		.col2 {
			color: white;
			height: 0.24rem;
			width: 0.44rem;
			line-height: 0.24rem;
			text-align: center;
			border-radius: 4px;
		}
		.col2.ji {
			background-color: @ji;
		}
		.col2.banji {
			background-color: @banji;
		}
		.col2.xiong {
			background-color: @xiong;
		}
		.col3 {
			color: @black;
		}
	}
	.geo-wuge-structure-detail-ele-con {
		display: flex;
		margin-top: 0.18rem;
		.geo-wuge-structure-detail-ele-col1 {
			width: 0.44rem;
			flex-shrink: 0;
			color: #999;
		}
		.geo-wuge-structure-detail-ele-col2 {
			padding-bottom: 0.16rem;
			p {
				color: @black666;
			}
			.hilight {
				background: rgba(237, 72, 72, .1);
				color: @red;
				padding: 4px 8px;
				margin-top: 8px;
				white-space: pre-wrap;
			}
		}
	}
	.geo-wuge-structure-detail-ele:not(:first-child) {
		margin-top: 0.16rem;
	}
	.geo-wuge-structure-detail-ele:not(:last-child) {
		.geo-wuge-structure-detail-ele-con {
			.geo-wuge-structure-detail-ele-col2 {
				border-bottom: 1px solid #f1f1f1;
			}
		}
	}
}
.geo-wuge-others {
	.geo-wuge-others-ele {
		padding-top: 0.18rem;
		padding-bottom: 0.16rem;
		.tag {
			border: 1px solid @red;
			color: @red;
			padding: 0 8px;
			line-height: 0.24rem;
			border-radius: 4px;
			display: inline-block;
			margin-bottom: 8px;
		}
	}
	.geo-wuge-others-ele + .geo-wuge-others-ele {
		border-top: 1px solid #f1f1f1;
	}
}
</style>