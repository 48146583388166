<template>
	<div>
		<Card theme="center" :title="'心理学评分'">
			<div class="geo-detail-more-type-card-header">
				<div class="geo-detail-more-type-card-header-chart top">
					<CircleChart v-if="score" :title="$circleTitle(score)" :score="score" color="#EA7373" :radius="100" :barWidth="8"></CircleChart>
				</div>
			</div>
			<ContentCard :title="'心理学期望'">
				<div class="subtitle">期望宝宝长大后（品质）</div>
				<van-tag class="geo-tag" v-for="item in xinliData.expect.quality" :key="item">{{item}}</van-tag>
				<div class="geo-line geo-margin-top"></div>
				<div class="subtitle geo-padding-top">宝宝的性格标签（性格）</div>
				<van-tag class="geo-tag" v-for="item in xinliData.expect.character" :key="item">{{item}}</van-tag>
			</ContentCard>
			<ContentCard :title="'心理学印象'">
				<div>
					{{xinliData.impression_text}}
				</div>
				<div class="chart-box">
					<div class="chart-header">大家对“<em class="geo-em">{{xinliData.first_impression.word}}</em>”字的印象</div>
					<div class="chart-inner geo-margin-top">
						<CircleChartBase
							v-if="chart1.length"
							:data="chart1"
							:colors="colors"
							:radius="1"
							:innerRadius="0.5"
							:height="160"></CircleChartBase>
					</div>
				</div>
				<div class="chart-box">
					<div class="chart-header">大家对“<em class="geo-em">{{xinliData.second_impression.word}}</em>”字的印象</div>
					<div class="chart-inner geo-margin-top">
						<CircleChartBase
							v-if="chart2.length"
							:data="chart2"
							:colors="colors"
							:radius="1"
							:innerRadius="0.5"
							:height="160"></CircleChartBase>
					</div>
				</div>
				<div class="chart-box">
					<div class="chart-header">大家对“<em class="geo-em">{{xinliData.all_impression.word}}</em>”的名字印象</div>
					<div class="chart-inner geo-margin-top">
						<CircleChartBase
							v-if="chartall.length"
							:data="chartall"
							:colors="colors"
							:radius="1"
							:innerRadius="0.5"
							:height="160"></CircleChartBase>
					</div>
				</div>
				<div class="geo-hilight-box">以上印象是根据近十五年大数据分析，得出的大家对名字叫{{xinliData.all_impression.word}}的印象分析，可供您参考。</div>
			</ContentCard>
		</Card>
		<Card theme="center" :title="'心理学分析'">
			<div>
				{{xinliData.analysis_text}}
			</div>
		</Card>
	</div>
</template>

<script>
import Card from '../Card'
import ContentCard from '../ContentCard'
import CircleChart from '../CircleChart'
import CircleChartBase from '../CircleChartBase'
import { getXinlixue } from '../../api/Main'
export default {
	name: 'XinlixueCard',
	props: {
		title: String,
		data: Object,
		score: Number || String
	},
	components: {
		Card,
		ContentCard,
		CircleChart,
		CircleChartBase
	},
	computed: {
		scoreList() {
			return this.data.score_list
		},
		typeData() {
			if (this.data.free_names[this.$route.query.index]) {
				return this.data.free_names[this.$route.query.index]
			}
			return {}
		},
		chart1() {
			return this.xinliData.first_impression.map.map((item, index) => {
				return {
					type: item,
					name: item,
					value: this.xinliData.first_impression.score[index]
				}
			})
		},
		chart2() {
			return this.xinliData.second_impression.map.map((item, index) => {
				return {
					type: item,
					name: item,
					value: this.xinliData.second_impression.score[index]
				}
			})
		},
		chartall() {
			return this.xinliData.all_impression.map.map((item, index) => {
				return {
					type: item,
					name: item,
					value: this.xinliData.all_impression.score[index]
				}
			})
		}
	},
	data() {
		return {
			colors: ['#5B8FF9', '#9270CA', '#E8684A', '#FF9D4D', '#269A99', '#40B9F9'],
			xinliData: {
				analysis_text: '',
				impression_text: '',
				expect: {
					quality: [],
					character: []
				},
				all_impression: {
					map: [],
					score: [],
					word: ''
				},
				first_impression: {
					map: [],
					score: [],
					word: ''
				},
				second_impression: {
					map: [],
					score: [],
					word: ''
				}
			},
			chartdata1: [
				{
					type: '有文采',
					name: '有文采',
					value: 70
				},
				{
					type: '博学',
					name: '博学',
					value: 30
				}
			]
		}
	},
	created() {
		this.getData()
	},
	methods: {
		async getData() {
			const {data} = await getXinlixue({
				params: {
					ming_zi: this.typeData.ming
				}
			})
			this.xinliData = data
		}
	}
}
</script>

<style lang="less" scoped>
@import '../../styles/var.less';
.subtitle {
	margin-bottom: 0.16rem;
}
.chart-box {
	margin-top: 0.23rem;
	.chart-header {
		font-size: 0.18rem;
		line-height: 0.25rem;
		color: #000000;
		em {
			color: @red;
			font-style: normal;
		}
	}
	.chart-inner {
		width: 100%;
		height: 225px;
	}
}
</style>