import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Detail from '../views/Detail.vue'
import DetailMore from '../views/DetailMore.vue'
import Lucky from '@/views/Lucky.vue'
import LuckyDetail from '@/views/LuckyDetail.vue'
import ScoreDetail from '@/views/ScoreDetail.vue'
import User from '@/views/User.vue'
import Book from '@/views/Book.vue'
import NameCollection from '@/views/NameCollection.vue'
import ScoreHistory from '@/views/ScoreHistory.vue'
import CommentHistory from '@/views/CommentHistory.vue'
import LuckyHistory from '@/views/LuckyHistory.vue'
import MoreComent from '@/views/MoreComent.vue'
import Statement from '@/views/Statement.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '宝宝取名'
    }
  },
  {
    path: '/statement',
    name: 'Statement',
    component: Statement,
    meta: {
      title: '隐私保护声明'
    }
  },
  {
    path: '/coment',
    name: 'HomeMoreComent',
    component: MoreComent,
    meta: {
      title: '宝宝取名'
    }
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    component: Detail,
    meta: {
      title: '宝宝取名'
    }
  },
  {
    path: '/score',
    name: 'ScoreDetail',
    component: ScoreDetail,
    meta: {
      title: '宝宝取名'
    }
  },
  {
    path: '/detailmore/:id',
    name: 'DetailMore',
    component: DetailMore,
    meta: {
      title: '宝宝取名'
    }
  },
  {
    path: '/lucky',
    name: 'Lucky',
    component: Lucky,
    meta: {
      title: '运势'
    }
  },
  {
    path: '/lucky/detail',
    name: 'LuckyDetail',
    component: LuckyDetail,
    meta: {
      title: '今日运势'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/user/booking',
    name: 'UserBooking',
    component: Book,
    meta: {
      title: '预约详情'
    }
  },
  {
    path: '/user/name-collection',
    name: 'UserNameCollection',
    component: NameCollection,
    meta: {
      title: '名字收藏'
    }
  },
  {
    path: '/user/score-history',
    name: 'UserScoreHistory',
    component: ScoreHistory,
    meta: {
      title: '历史测分'
    }
  },
  {
    path: '/user/comment-history',
    name: 'UserCommentHistory',
    component: CommentHistory,
    meta: {
      title: '历史评论'
    }
  },
  {
    path: '/user/lucky-history',
    name: 'UserLuckyHistory',
    component: LuckyHistory,
    meta: {
      title: '往期运势'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
