<template>
	<div class="yinxingyi">
		<Card theme="center" :title="'音形义评分'">
			<div class="geo-detail-more-type-card-header">
				<div class="geo-detail-more-type-card-header-chart top">
					<CircleChart v-if="score !== undefined" :title="$circleTitle(score)" :score="score" color="#EA7373" :radius="100" :barWidth="8"></CircleChart>
				</div>
			</div>
			<div class="radar-box flex">
				<div class="radar-box-item radar-box-left border-box">
					<RadarChart v-if="radarData" :data="radarData" :indicator="radarIndicator"></RadarChart>
				</div>
				<div class="radar-box-item radar-box-right border-box">
					<div class="radar-box-item-ceil">
						<div class="up-item">音：{{yin.score}}分</div>
						<div class="bottom-item">（占音形义起名{{yin.percentage}}%）</div>
					</div>
					<div class="radar-box-item-ceil">
						<div class="up-item">形：{{xing.score}}分</div>
						<div class="bottom-item">（占音形义起名{{xing.percentage}}%）</div>
					</div>
					<div class="radar-box-item-ceil">
						<div class="up-item">义：{{yi.score}}分</div>
						<div class="bottom-item">（占音形义起名{{yi.percentage}}%）</div>
					</div>
				</div>
			</div>
			<ContentCard :title="'字义分析'">
				<div v-for="(item, index) in words" :key="item.jian + index">
					<div class="font-card-wrap">
						<div class="font-header-box flex">
							<div class="geo-name-font-box">
								<div class="geo-name-font">{{item.jian}}</div>
							</div>
							<div class="font-detail-item">
								<div class="name">拼音</div>
								<div class="con">{{item.ping_yin}}</div>
							</div>
							<div class="font-detail-item">
								<div class="name">繁体</div>
								<div class="con">{{item.fan_ti}}</div>
							</div>
							<div class="font-detail-item">
								<div class="name">五行</div>
								<div class="con">{{item.wu_xing}}</div>
							</div>
						</div>
						<div class="geo-line geo-margin-top"></div>
						<div class="geo-list-box">
							<div class="geo-list-row flex">
								<div class="geo-list-col1 geo-text-red">字义</div>
								<div class="geo-list-col2 geo-border-bottom geo-padding-bottom">
									{{item.zi_yi}}
								</div>
							</div>
							<div class="geo-list-row flex">
								<div class="geo-list-col1 geo-text-red">用字</div>
								<div class="geo-list-col2 geo-border-bottom geo-padding-bottom">
									{{item.yong_zi}}
								</div>
							</div>
							<div class="geo-list-row flex">
								<div class="geo-list-col1 geo-text-red">来源</div>
								<div class="geo-list-col2 geo-padding-bottom">
									{{item.source}}
								</div>
							</div>
						</div>
					</div>
					<div class="geo-line geo-margin-bottom" v-if="index + 1 !== words.length"></div>
				</div>
			</ContentCard>
			<ContentCard :title="'字音分析'" :score="yin.score">
				<div class="font-card-wrap">
					<div v-for="(item, index) in words" :key="item.jian + 'yin' + index">
						<div class="font-header-box flex flex-justify-start">
							<div class="geo-name-font-box">
								<div class="geo-name-font">{{item.jian}}</div>
							</div>
							<div class="geo-margin-left">
								<div class="flex flex-justify-start font-detail-item-row">
									<div class="font-detail-item flex flex-justify-start horiz">
										<div class="name">拼音</div>
										<div class="con">{{item.ping_yin}}</div>
									</div>
									<div class="font-detail-item flex flex-justify-start horiz">
										<div class="name">声母</div>
										<div class="con">{{item.sheng_mu}}</div>
									</div>
									<div class="font-detail-item flex flex-justify-start horiz">
										<div class="name">韵母</div>
										<div class="con">{{item.yun_mu}}</div>
									</div>
								</div>
								<div class="flex flex-justify-start font-detail-item-row">
									<div class="font-detail-item flex flex-justify-start horiz">
										<div class="name">音调</div>
										<div class="con">{{item.ying_diao}}</div>
									</div>
									<div class="font-detail-item flex flex-justify-start horiz">
										<div class="name">发音</div>
										<div class="con">{{item.fa_yin}}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="geo-line geo-margin-top geo-margin-bottom" v-if="index + 1 !== words.length"></div>
					</div>
				</div>
			</ContentCard>
		</Card>
	</div>
</template>

<script>
import Card from '../Card'
import ContentCard from '../ContentCard'
import CircleChart from '../CircleChart'
import RadarChart from '../RadarChart'
import { getYinxingyi } from '../../api/Main'
export default {
	name: 'YinxingyiCard',
	components: {
		Card,
		ContentCard,
		CircleChart,
		RadarChart
	},
	props: ['title', 'data', 'score'],
	data() {
		return {
			yxyData: {},
			yin: {},
			xing: {},
			yi: {},
			w1: {},
			w2: {},
			radarData: null,
			radarIndicator: [
				{name: '音', max: 100},
				{name: '形', max: 100},
				{name: '义', max: 100}
			]
		}
	},
	computed: {
		words() {
			return [
				Object.assign({jian: this.nameData.ming[0]}, this.w1),
				Object.assign({jian: this.nameData.ming[1]}, this.w2),
			]
		},
		nameData() {
			return this.data.free_names[this.$route.query.index]
		},
		scoreList() {
			return this.data.score_list
		},
	},
	mounted() {
		console.log(this.nameData.ming)
		this.initYinxingyi()
	},
	methods: {
		async initYinxingyi() {
			const {data, msg} = await getYinxingyi({
				params: {
					first_word: this.nameData.ming[0],
					second_word: this.nameData.ming[1]
				}
			})
			this.yxyData = data
			this.yin = data.score_table.yin
			this.xing = data.score_table.xing
			this.yi = data.score_table.yi
			this.w1 = data.first_word
			this.w2 = data.second_word
			this.radarData = [{
				name: '音形义',
				value: [this.yin.score, this.xing.score, this.yi.score]
			}]
		}
	}
}
</script>

<style lang="less" scoped>
@import '../../styles/var.less';
.font-detail-item {
	text-align: center;
	line-height: 0.2rem;
	.name {
		color: #999;
		flex-shrink: 0;
	}
	.con {
		margin-top: 2px;
		color: @black;
		flex-shrink: 0;
	}
}
.font-detail-item.horiz {
	flex-wrap: nowrap;
	width: 0.78rem;
	flex-shrink: 0;
	.con {
		margin-top: 0;
		margin-left: 8px;
	}
}
.font-detail-item-row + .font-detail-item-row {
	margin-top: 8px;
}
.radar-box {
	margin-bottom: 0.32rem;
	.radar-box-item {
		width: 50%;
		height: 1.88rem;
	}
	.radar-box-left {
		border-right: @borderColor 1px solid;
		padding-right: 8px;
	}
	.radar-box-right {
		margin-left: 8px;
	}
	.radar-box-item-ceil + .radar-box-item-ceil {
		border-top: @borderColor 1px solid;
	}
	.radar-box-item-ceil {
		padding: 0.12rem 0;
		.up-item {
			font-size: 0.16rem;
			color: @black;
			line-height: 0.22rem;
			margin-bottom: 4px;
			padding-left: 0.1rem;
		}
		.bottom-item {
			font-size: 0.14rem;
			color: #999;
			line-height: 0.20rem;
		}
	}
	.radar-box-item-ceil:first-child {
		padding-top: 0;
	}
	.radar-box-item-ceil:last-child {
		padding-bottom: 0;
	}
}
</style>