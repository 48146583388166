<template>
	<div class="geo-content-card">
		<div class="header flex">
			<div class="left flex">
				<i v-if="icon" class="geo-icon icon-image icon" :class="'icon-' + icon"></i>
				<i v-else class="icon icon_default"></i>
				<div class="title">{{title}}</div>
			</div>
			<div class="right flex" v-if="score !== undefined">
				<div class="bar-box">
					<div class="bar" :style="{width: score + '%'}"></div>
				</div>
				<div>{{score}}分</div>
			</div>
			<div v-else class="right flex">
				<slot name="right"></slot>
			</div>
		</div>
		<div class="content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'contentCard',
	props: {
		title: String,
		icon: {
			type: String,
		},
		score: Number
	},
	data() {
		return {
			
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.geo-content-card + .geo-content-card{
	margin-top: 0.32rem;
}
.geo-content-card {
	.header {
		color: #333;
		font-size: 0.16rem;
		line-height: 0.2rem;
		font-weight: 500;
		margin-bottom: 0.16rem;
		.right {
			font-size: 0.14rem;
			line-height: 0.2rem;
			color: @red;
			font-weight: 400;
			.bar-box {
				margin-right: 8px;
				position: relative;
				width: 0.8rem;
				height: 0.1rem;
				background: #EEE8E8;
				border-radius: 8px;
				overflow: hidden;
				.bar {
					height: 100%;
					background: @red;
					position: absolute;
					left: 0;
					top: 0;
					border-radius: 8px;
				}
			}
		}
	}
	.content {
		font-size: 0.14rem;
		color: #666;
		line-height: 0.2rem;
	}
	.icon {
		display: inline-block;
		margin-right: 3px;
	}
	.icon-image {
		width: 0.24rem;
		height: 0.24rem;
	}
	.icon_default {
		width: 4px;
		height: 16px;
		background: #ED4848;
		border-radius: 2px;
	}
}

</style>