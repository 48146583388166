<template>
	<div class="geo-guoxue-like-god">
		<div class="geo-guoxue-like-god-row" v-for="(item, key) in data" :key="key">
			<div class="geo-guoxue-like-god-label">{{godMap[key]}}</div>
			<div class="geo-guoxue-like-god-info">{{item}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AnalyCard',
	props: {
		data: {
			type: Object,
			default: function() {
				return {}
			}
		}
	},
	data() {
		return {
			godMap: {
				same: '同类',
				diff: '异类',
				total: '综合'
			}
		}
	}
}
</script>

<style lang="less" scoped>
.geo-guoxue-like-god {
	.geo-guoxue-like-god-label {
		width: (311% - 267) / 311 * 100;
		font-size: 0.16rem;
		color: #EA7373;
		line-height: 0.22rem;
	}
	.geo-guoxue-like-god-info {
		width: 267% / 311 * 100;
		padding-bottom: 0.18rem;
	}
	.geo-guoxue-like-god-row {
		display: flex;
	}
	.geo-guoxue-like-god-row:not(:first-child) {
		margin-top: 0.18rem;
	}
	.geo-guoxue-like-god-row:not(:last-child) {
		.geo-guoxue-like-god-info {
			border-bottom: 1px solid #f1f1f1;
		}
	}
}
</style>