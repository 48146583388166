<template>
  <div class="home">
    <div class="form-box">
      <div class="form-box-inner">
        <Tabs v-model:active="active">
          <Tab title="宝宝取名">
            <Form>
              <Field name="radio" label="出生状态">
                <template #input>
                  <radio-group @change="handleBirthStatusChange" v-model="state.birth_status" direction="horizontal">
                    <radio name="1">已出生</radio>
                    <radio name="2">未出生</radio>
                  </radio-group>
                </template>
              </Field>
              <Field
                v-model="state.surname"
                name="姓氏"
                label="姓氏"
                placeholder="请输入姓氏"></Field>
              <Field name="radio" label="性别">
                <template #input>
                  <radio-group v-model="state.sex" direction="horizontal">
                    <radio name="1">男</radio>
                    <radio name="2">女</radio>
                  </radio-group>
                </template>
              </Field>
              <Field
                readonly
                clickable
                name="birth_place"
                v-model="state.birth_place"
                label="出生地"
                placeholder="请选择出生地"
                @click="showArea = true"
              />
              <Field
                readonly
                clickable
                name="calendar"
                v-model="state.date"
                :label="birthDateLabel">
                <template #input>
                  <DatePicker
                    id="geo-order"
                    ref="statePicker"
                    @change="handleStateDateChange"
                    :placeholder="`请选择${birthDateLabel}`"
                    dateid="orderdate"
                    hourid="orderhour"
                    minuteid="orderminute"
                    :min="minDate"
                    :max="maxDate"></DatePicker>
                </template>
              </Field>
              <div class="btn-box">
                <!-- <button class="btn score-btn" @click="gotoDetail">立即获取美名</button> -->
                <div class="geo-btn red full" @click="gotoDetail">
                  <div class="geo-btn-bg">立即获取美名</div>
                </div>
              </div>
              <div class="tip-box">{{active === 0 ? `已有 ${nums.name_num} 个宝宝获得吉名` : `已有 ${nums.test_score_num} 个名字参与测分`}}</div>
            </Form>
          </Tab>
          <Tab title="名字测分">
            <Form>
              <Field
                v-model="testState.surname"
                name="姓氏"
                label="姓氏"
                placeholder="请输入姓氏"></Field>
              <Field
                v-model="testState.ming"
                name="名字"
                label="名字"
                placeholder="请输入名字"></Field>
              <Field name="radio" label="性别">
                <template #input>
                  <radio-group v-model="testState.sex" direction="horizontal">
                    <radio name="1">男</radio>
                    <radio name="2">女</radio>
                  </radio-group>
                </template>
              </Field>
              <Field
                readonly
                clickable
                name="calendar"
                v-model="testState.date"
                label="出生时间"
                placeholder="请选择出生时间">
                <template #input>
                  <DatePicker
                    id="geo-test"
                    @change="handleTestDateChange"
                    placeholder="请选择出生时间"
                    dateid="testdate"
                    hourid="testhour"
                    minuteid="testminute"
                    :min="minTestDate"
                    :max="maxTestDate"></DatePicker>
                </template>
              </Field>
              <div class="btn-box">
                <!-- <button class="btn score-btn">立即获取分数</button> -->
                <div class="geo-btn red full" @click="gotoScoreDetail">
                  <div class="geo-btn-bg">立即获取分数</div>
                </div>
              </div>
              <div class="tip-box">已有 {{nums.test_score_num}} 个名字参与测分</div>
            </Form>
          </Tab>
        </Tabs>
      </div>
    </div>
    <Comment @show="showComment = true"></Comment>
    <div class="home-coment-more-btn" @click="gotoMoreComent">更多评价</div>
    <div class="geo-home-others">
      <img src="../assets/home.png" alt="">
    </div>
    <van-action-sheet v-model="showBirthSheet">
      <van-datetime-picker
        @cancel="showBirthSheet = false"
        @confirm="onCanConfirm"
        v-model="currentDate"
        type="datetime"
        title="选择完整时间"
        :formatter="dateFormater"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-action-sheet>
    <van-action-sheet v-model="showBirthSheetTest">
      <van-datetime-picker
        @cancel="showBirthSheetTest = false"
        @confirm="onCanConfirmTest"
        v-model="currentDateTest"
        type="datetime"
        title="选择完整时间"
        :formatter="dateFormater"
        :min-date="minTestDate"
      />
    </van-action-sheet>
    <Popup v-model:show="showArea" round position="bottom">
      <Area
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </Popup>
    <van-overlay :show="showLoading" z-index="999">
      <div class="gen-loading-wrapper" @click.stop>
        <div class="loading-box">
          <img class="loading-box-img" src="../assets/loading-taiji.png" alt="">
          <div class="loading-box-tip">正在努力加载中...</div>
        </div>
        <div class="loading-desc-box">
          <div v-for="item in loadingItems" :key="item">{{item}}</div>
        </div>
        <img class="loading-footer" src="../assets/loading_icon.png" alt="">
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Tab, Tabs, Form, Field, RadioGroup, Radio, Popup, Area, Calendar, Toast } from 'vant'
import Comment from '../components/Comment'
import EditComent from '../components/EditComment'
import { createOrder, testScore, getPeopleNum } from '../api/Main'
import areaList from '../utils/area.js'
import detailsMap from '../utils/details'
import {formatNumberWith, isChn, dateFormater, formatBirthLabel} from '../utils/utils'
import DatePicker from '../components/datepicker'


const label = { offset: 12 };
export default {
  name: 'Home',
  data() {
    return {
      nums: {
        name_num: '',
        test_score_num: ''
      },
      loadingItems: [],
      showLoading: false,
      label,
      state: {
        birth_status: '1',
        surname: '',
        sex: '1',
        birth_place: '',
        date: '',
        birth_year: '',
        birth_month: '',
        birth_day: '',
        birth_hour: '',
        birth_minute: '',
        is_lunar: ''
      },
      testState: {
        surname: '',
        ming: '',
        sex: '1',
        date: '',
        birth_year: '',
        birth_month: '',
        birth_day: '',
        birth_hour: '',
        birth_minute: ''
      },
      minDate: 1940,
      maxDate: 2030,
      minTestDate: 1940,
      maxTestDate: 2030,
      currentDate: new Date(),
      currentDateTest: new Date(),
      active: 0,
      areaList,
      showBirthSheet: false,
      showBirthSheetTest: false,
      showArea: false,
      showComment: false,
      isDetailReady: false,
      isLoadingDone: false,
      orderId: null
    }
  },
  created() {
    this.getNums()
  },
  computed: {
    birthDateLabel() {
      return formatBirthLabel(this.state.birth_status)
    }
  },
  methods: {
    handleStateDateChange(d) {
      this.state.date = d.text
      this.state.birth_year = d.y
      this.state.birth_month = d.m
      this.state.birth_day = d.d
      this.state.birth_hour = d.h
      this.state.birth_minute = d.i
      this.state.is_lunar = d.isLunar
    },
    handleTestDateChange(d) {
      this.testState.date = d.text
      this.testState.birth_year = d.y
      this.testState.birth_month = d.m
      this.testState.birth_day = d.d
      this.testState.birth_hour = d.h
      this.testState.birth_minute = d.i
      this.testState.is_lunar = d.isLunar
    },
    dateFormater,
    async getNums() {
      const {data} = await getPeopleNum()
      this.nums = data
    },
    handleBirthStatusChange (e) {
      // this.resetDate()
      // if (e === '1') {
      //   this.minDate = 2011
      //   this.maxDate = new Date().getFullYear()
      // } else {
      //   this.minDate = new Date().getFullYear()
      //   this.maxDate = 2030
      // }
    },
    resetDate() {
      this.$refs.statePicker.clear()
      this.state.date = ''
      this.state.birth_year = ''
      this.state.birth_month = ''
      this.state.birth_day = ''
      this.state.birth_hour = ''
      this.state.birth_minute = ''
      this.state.is_lunar = ''
    },
    aniLoading () {
      let i = 0
      let interval = setInterval(() => {
        // console.log(i, detailsMap.length)
        if (i > detailsMap.length) {
          clearInterval(interval)
          this.loadingItems = []
          this.isLoadingDone = true
          console.log(this.isDetailReady, this.orderId)
          if (this.isDetailReady && this.orderId) {
            this.$router.push('/detail/' + this.orderId)
          }
        } else {
          detailsMap[i] && this.loadingItems.unshift(detailsMap[i].name + '分析')
          this.loadingItems[1] && (this.loadingItems[1] = this.loadingItems[1] + '完成')
          if (this.loadingItems.length === detailsMap.length) {
            this.loadingItems[0] = this.loadingItems[0] + '完成'
          }
          i++
        }
      }, 500)
    },
    gotoMoreComent() {
      this.$router.push('/coment')
    },
    async gotoDetail () {
      this.state.surname = this.state.surname.trim()
      if (!this.state.surname) return Toast({message: '姓氏必须填写'})
      if (!this.state.birth_place) return Toast({message: '出生地必须填写'})
      if (!this.state.birth_year) return Toast({message: '出生时间必须填写'})
      this.showLoading = true
      this.aniLoading()
      // this.state.sex = Number(this.state.sex)
      // this.state.birth_status = Number(this.state.birth_status)
      try {
        let {code, msg, data} = await createOrder({
          data: this.state
        })
        if (code === 0) {
          console.log(code)
          this.orderId = data.id
          this.isDetailReady = true
          if (this.isLoadingDone) {
            this.showLoading = false
            this.$router.push('/detail/' + data.id)
          }
        } else {
          Toast(msg || 'unknown error code ' + code)
          this.showLoading = false
          this.loadingItems = []
        }
      } catch(error) {
        this.showLoading = false
        this.loadingItems = []
      }
    },
    async gotoScoreDetail() {
      this.testState.surname = this.testState.surname.trim()
      this.testState.ming = this.testState.ming.trim()
      if (!this.testState.surname) return Toast({
        message: '请填写姓氏'
      })
      if (this.testState.surname.length > 2) return Toast({
        message: '姓氏不能超过两个字'
      })
      if (!this.testState.ming) return Toast({
        message: '请填写名字'
      })
      if (this.testState.ming.length > 2) return Toast({
        message: '名字不能超过两个字'
      })
      if (!(isChn(this.testState.surname) && isChn(this.testState.ming))) {
        return Toast({
          message: '请输入汉字'
        })
      }
      if (!this.testState.date) return Toast({
        message: '请选择出生时间'
      })
			try {
				Toast.loading({
					message: '加载中...',
					forbidClick: true
				})
				const { code, msg, data }  = await testScore({
					params: this.testState
				})
				if (code === 0) {
					Toast.clear()
					// this.oriData = cloneDeep(data)
					// data.desc = data.text
					// data.text = data.five_live.map((item, index) => {
					// 	return {
					// 		v: data.ming_zi[index],
					// 		s: item
					// 	}
					// })
					// data.score = data.total_score
          // this.scoreData = data
          this.$router.push({
            path: '/score?id=' + data.id
          })
				} else {
					Toast.clear()
					Toast.fail(msg || 'unknown error code ' + code)
				}
			} catch(err) {
				Toast.clear()
			}
    },
    onConfirm (values) {
      this.showArea = false
      this.state.birth_place = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
    },
    onCanConfirm (date) {
      this.state.birth_year = '' + date.getFullYear()
      this.state.birth_month = '' + (date.getMonth() + 1)
      this.state.birth_day = '' + date.getDate()
      this.state.birth_hour = '' + date.getHours()
      this.state.birth_minute = '' + date.getMinutes()

      this.state.date = `${date.getFullYear()}/${formatNumberWith(date.getMonth() + 1)}/${formatNumberWith(date.getDate())} ${formatNumberWith(date.getHours())}:${formatNumberWith(date.getMinutes())}`
      this.showBirthSheet = false
    },
    onCanConfirmTest (date) {
      this.testState.birth_year = '' + date.getFullYear()
      this.testState.birth_month = '' + (date.getMonth() + 1)
      this.testState.birth_day = '' + date.getDate()
      this.testState.birth_hour = '' + date.getHours()
      this.testState.birth_minute = '' + date.getMinutes()

      this.testState.date = `${date.getFullYear()}/${formatNumberWith(date.getMonth() + 1)}/${formatNumberWith(date.getDate())} ${formatNumberWith(date.getHours())}:${formatNumberWith(date.getMinutes())}`
      this.showBirthSheetTest = false
    }
  },
  components: {
    Comment,
    EditComent,
    Tab,
    Tabs,
    Form,
    Field,
    RadioGroup,
    Radio,
    Popup,
    Area,
    Calendar,
    DatePicker
  }
}
</script>

<style lang="less" scoped>
.gen-loading-wrapper {
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
  .loading-box {
    width: 100%;
    height: 1.44rem;
    padding-top: 1.34rem;
    background-image: url('../assets/loading-bg.png');
    background-size: 100% auto;
    background-position: bottom center;
    background-repeat: no-repeat;
    text-align: center;
    .loading-box-img {
      width: 0.86rem;
      height: 0.84rem;
      /*animation (动画) :绑定选择器, 4s完成动画 linear(匀速) infinite(循环) */
      animation: taiji 3s linear infinite;
    }
    .loading-box-tip {
      font-size: 0.14rem;
      color: #333333;
      font-weight: 500;
      margin-top: 0.24rem;
    }
  }
  .loading-desc-box {
    text-align: center;
    font-size: 0.14rem;
    line-height: 0.26rem;
    color: #666666;
    div.active {
      font-weight: 500;
    }
  }
  .loading-footer {
    position: absolute;
    width: 2.02rem;
    height: 0.19rem;
    margin-left: -1.01rem;
    left: 50%;
    bottom: 0.42rem;
  }
}
@keyframes taiji {
  0% {
    /*rotate(2D旋转) scale(放大或者缩小) translate(移动) skew(翻转)*/
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style lang="less">
@red: #FA5050;
.geo-home {
  &-others {
    img {
      width: 100%;
      display: block;
    }
  }
}
.home {
  padding-top: 1.77rem;
  background-image: url('../assets/header.png');
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 100vh;
  box-sizing: border-box;
  max-width: 1024px;
  margin: 0 auto;
}
.form-box {
  // padding: 0 0.12rem;
  width: 3.60rem;
  margin: 0 auto;
  max-width: 540px;
  background-size: 100% auto;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: url('../assets/bg_bottom.png');
  .form-box-inner {
    padding: 0.16rem 0.24rem 0.26rem;
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url('../assets/bg_top.png');
  }
}
.btn-box {
  width: (303% / 343 * 100);
  text-align: center;
  margin: 0.16rem auto 0;
}
.btn {
  width: (303% / 343 * 100);
  height: 0.44rem;
  border: none;
  padding: 0;
  margin: 0;
  color: white;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
}
.score-btn {
  background-image: url('../assets/score-btn.png');
}
.tip-box {
  color: @red;
  text-align: center;
  font-size: 11px;
  margin-top: 0.14rem;
}
.home .van-field__label {
  text-align: justify;
  text-align-last: justify;
  margin-right: 0.17rem;
  width: 0.65rem;
  white-space: nowrap;
}
.home {
  .van-tab--active {
    color: @red;
  }
  .van-tabs__line {
    background-color: @red;
  }
  .van-radio__icon--checked .van-icon {
    background-color: @red;
    border-color: @red;
  }
}
.home-coment-more-btn {
  color: @red;
  line-height: 0.44rem;
  text-align: center;
  font-size: 0.14rem;
  margin-top: -0.16rem;
  margin-bottom: 0.16rem;
}
</style>
