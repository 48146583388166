<template>
	<div>
		<Card theme="center" :title="title + '评分'">
			<div class="geo-detail-more-type-card-header">
				<div class="geo-detail-more-type-card-header-chart">
					<CircleChart :title="$circleTitle(score)" :score="score" color="#EA7373" :radius="100" :barWidth="8"></CircleChart>
				</div>
			</div>
			<ContentCard title="国学起名分析详解">
				<table class="geo-table">
					<tr>
						<td>真太阳时公历</td>
						<td>{{typeData.gong_li.year}}</td>
						<td>{{typeData.gong_li.month}}</td>
						<td>{{typeData.gong_li.day}}</td>
						<td>{{typeData.gong_li.hour}}</td>
					</tr>
					<tr>
						<td>真太阳时农历</td>
						<td>{{typeData.nong_li.year}}</td>
						<td>{{typeData.nong_li.month}}</td>
						<td>{{typeData.nong_li.day}}</td>
						<td>{{typeData.nong_li.hour}}</td>
					</tr>
					<tr>
						<td>八  字</td>
						<td v-for="(item, index) in typeData.ba_zi" :key="item + index">{{item}}</td>
					</tr>
					<tr>
						<td>五  行</td>
						<td v-for="(item, index) in typeData.wu_xing" :key="item + index">{{item}}</td>
					</tr>
					<tr>
						<td>纳  音</td>
						<td v-for="(item, index) in typeData.na_yin" :key="item + index">{{item}}</td>
					</tr>
				</table>
			</ContentCard>
			<ContentCard title="五行强弱分析">
				<WuxingCard :analy="data.analysis_list"></WuxingCard>
			</ContentCard>
			<ContentCard title="喜神分析">
				<AnalyCard :data="data.analysis_list.like_use_god"></AnalyCard>
				<div class="geo-line"></div>
			</ContentCard>
			<div class="geo-text">
				{{data.analysis_list.guo_xue.text}}
			</div>
		</Card>
	</div>
</template>

<script>
import Card from '../Card'
import ContentCard from '../ContentCard'
import CircleChart from '../CircleChart'
import WuxingCard from '../WuxingCard'
import AnalyCard from '../AnalyCard' 
export default {
	name: 'XingzuoCard',
	props: {
		title: String,
		data: Object,
		score: Number || String
	},
	components: {
		Card,
		ContentCard,
		CircleChart,
		WuxingCard,
		AnalyCard
	},
	computed: {
		scoreList() {
			return this.data.score_list
		},
		typeData() {
			return this.data.analysis_list.guo_xue
		}
	},
	data() {
		return {
			
		}
	}
}
</script>

<style lang="less" scoped>
.geo-guoxue-like-god {
	.geo-guoxue-like-god-label {
		width: (311% - 267) / 311 * 100;
		font-size: 0.16rem;
		color: #EA7373;
		line-height: 0.22rem;
	}
	.geo-guoxue-like-god-info {
		width: 267% / 311 * 100;
		padding-bottom: 0.18rem;
	}
	.geo-guoxue-like-god-row {
		display: flex;
	}
	.geo-guoxue-like-god-row:not(:first-child) {
		margin-top: 0.18rem;
	}
	.geo-guoxue-like-god-row:not(:last-child) {
		.geo-guoxue-like-god-info {
			border-bottom: 1px solid #f1f1f1;
		}
	}
}
</style>