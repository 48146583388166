<template>
	<div class="geo-lucky-container border-box">
		<div class="geo-lucky-card">
			<div class="lucky-date-box">
				<div class="year">{{year}}</div>
				<div class="date">{{`${month}月${day}日`}} {{week}}</div>
			</div>
			<div class="lucky-btn-box">
				<div class="geo-btn yellow full">
					<div class="geo-btn-bg" @click="gotoDetail">今日运势，算上一卦</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getDayTimestamp, luckyName } from '../utils/utils'
var weekday = new Array(7);
weekday[0] = "星期日";
weekday[1] = "星期一";
weekday[2] = "星期二";
weekday[3] = "星期三";
weekday[4] = "星期四";
weekday[5] = "星期五";
weekday[6] = "星期六";
const date = new Date()
const year = date.getFullYear()
const month = ((date.getMonth() + 1) > 9) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))
const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
const week = weekday[date.getDay()]

export default {
	name: 'Lucky',
	data() {
		return {
			year,
			month,
			day,
			week
		}
	},
	beforeRouteEnter (to, from, next) {
		let luckyCache = localStorage.getItem(luckyName)
		if (luckyCache == getDayTimestamp(new Date())) {
			setTimeout(() => {
				next('/lucky/detail')
			}, 100)
		} else {
			localStorage.removeItem(luckyName)
			return next()
		}
	},
	methods: {
		gotoDetail() {
			localStorage.setItem(luckyName, getDayTimestamp(new Date()))
			this.$router.push('/lucky/detail')
		}
	}
}
</script>

<style lang="less" scoped>
.geo-lucky-container {
	height: calc(100vh - 50px);
	width: 100%;
	background: #850D09;
	padding: 0 0.16rem;
	display: flex;
	justify-content: center;
	align-items: center;
	.geo-lucky-card {
		width: 100%;
		height: 5.21rem;
		background-image: url('../assets/luckybg.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		position: relative;
	}
	.lucky-btn-box {
		position: absolute;
		padding: 0 0.56rem;
		bottom: 0.73rem;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		.geo-btn {
			animation: aniBtn 0.5s ease-in alternate infinite;
		}
	}
}
.lucky-date-box {
	color: #FFD496;
	text-shadow: 0px 2px 1px #93130F;
	position: absolute;
	left: 0.35rem;
	top: 0.32rem;
	line-height: 0.33rem;
	.year {
		font-size: 0.24rem;
	}
	.date {
		font-size: 0.16rem;
	}
}
@keyframes aniBtn {
  0% {
    /*rotate(2D旋转) scale(放大或者缩小) translate(移动) skew(翻转)*/
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>