<template>
	<div class="user-name-collection-container">
		<div class="user-name-collection-list" v-if="isContent">
			<Card @click.native="gotoDetail(item)" v-for="(item, index) in scoreHistory" :key="item.id" theme="cancelcollect" title="取消收藏" style="position: relative;" @cancelSuccess="cancelSuccess" :nameId="item.id" :isScore="true">
				<NameCard :wuxing="false" :orderIndex="index" :sex="item.sex" :data="transData(item)"></NameCard>
			</Card>
		</div>
		<div class="geo-empty-container" v-if="isEmpty">
			<van-empty
				class="custom-image"
				:image="require('../assets/empty_default.png')"
				description="暂无数据" />
		</div>
	</div>
</template>

<script>
import { getScoreHistory } from '../api/User'
import Card from '../components/Card'
import NameCard from '../components/NameCard'

export default {
	name: 'UserNameCollection',
	components: {
		Card,
		NameCard,
	},
	data() {
		return {
			// scoreData
			isContent: false,
			isEmpty: false,
			scoreHistory: []
		}
	},
	created() {
		this.getData()
	},
	methods: {
		gotoDetail(item) {
			this.$router.push({
				path: '/score?id=' + item.score_id
			})
		},
		transData(item) {
			const data = {}
			data.text = item.ming_zi.split('').map(f => {
				return {
					v: f
				}
			})
			data.score = item.total_score
			return data
		},
		async getData() {
			const {data} = await getScoreHistory()
			this.scoreHistory = data || []
			this.isContent = !!this.scoreHistory.length
			this.isEmpty = !this.isContent
		},
		cancelSuccess(index) {
			this.scoreHistory.splice(index, 1)
			this.isEmpty = !this.scoreHistory.length
		},
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.user-name-collection-container {
	background: @bgcolor;
	min-height: 100vh;
	.user-name-collection-list {
		padding: 0.16rem;
	}
}
</style>