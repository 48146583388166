<template>
	<div class="score-detail-container">
		<Card v-if="scoreData.desc" theme="collect" title="收藏" :isScore="true" :scoreData="oriData">
			<NameCard :data="scoreData"></NameCard>
			<div class="geo-line"></div>
			<ScoreCard :table="scoreData.score_table"></ScoreCard>
			<div class="geo-line"></div>
			<div class="geo-padding-top geo-padding-bottom">
				{{scoreData.desc}}
			</div>
			<div class="geo-detail-btns flex">
				<div class="geo-btn yellow full" @click="gotoBooking">
					<div class="geo-btn-bg geo-detail-btn-book">
						<div>预约大师</div>
						<small>（获取满分美名）</small>
					</div>
				</div>
			</div>
		</Card>
	</div>
</template>

<script>
import { Toast } from 'vant'
import cloneDeep from 'lodash.clonedeep'
import { testScore, getTestScoreDetail } from '../api/Main'
import Card from '../components/Card'
import NameCard from '../components/NameCard'
import ScoreCard from '../components/ScoreCard'
// import detialJson from '../utils/detail.json'

// const scoreData = detialJson.data.free_names[0]

export default {
	name: 'ScoreDetail',
	components: {
		Card,
		NameCard,
		ScoreCard
	},
	data() {
		return {
			oriData: {},
			scoreData: {}
		}
	},
	created() {
		this.getData()
	},
	methods: {
		gotoBooking() {
			const token = window.localStorage.getItem('access_token')
			if (!token) {
				// return this.$router.push('/user?showLogin=1')
				return this.$EventBus.$emit('showLogin', true)
			}
			this.$router.push('/user/booking?redirect=' + encodeURIComponent(this.$route.fullPath))
		},
		async getData() {
			try {
				Toast.loading({
					message: '加载中...',
					forbidClick: true
				})
				const { code, msg, data }  = await getTestScoreDetail({}, this.$route.query.id)
				if (code === 0) {
					Toast.clear()
					this.oriData = cloneDeep(data)
					data.desc = data.text
					data.text = data.five_live.map((item, index) => {
						return {
							v: data.ming_zi[index],
							s: item
						}
					})
					data.score = data.total_score
					this.scoreData = data
				} else {
					Toast.clear()
					Toast.fail(msg || 'unknown error code ' + code)
				}
			} catch(err) {
				Toast.clear()
			}
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.score-detail-container {
	background: @bgcolor;
	height: 100vh;
	box-sizing: border-box;
	padding: 0.16rem;
}
</style>