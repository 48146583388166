<template>
	<v-chart :options="optionData" :init-options="initOptions"></v-chart>
</template>

<script>
import ECharts from 'vue-echarts'
import * as echarts from 'echarts/dist/echarts.js'
import 'echarts/lib/chart/line'

const options = {
	grid: {
		x: 60,
		y: 45,
		x2: 8,
		y2: 43
	},
	xAxis: {
		show: true,
		type: 'category',
		data: ['1960', '1970', '1980', '1990', '2000', '2010', '2020'],
		spiltLine: {
			show: false
		}
	},
	yAxis: {
		type: 'value',
		show: true,
		spiltLine: {
			show: false
		}
	},
	series: [{
		symbol: 'circle',
		symbolSize: 8,
		data: [8200, 93200, 90100, 9340, 12900, 133000, 13200],
		type: 'line',
		smooth: true,
		itemStyle: {
			normal: {
				color: '#6DC8EC',
				borderColor: '#fff',
				borderWidth: 1
			}
		},
		lineStyle: {
			normal: {
				color: '#6DC8EC'
			}
		}
	},{
		symbol: 'circle',
		symbolSize: 8,
		data: [82000, 9300, 90100, 9340, 120000, 133000, 13000],
		type: 'line',
		smooth: true,
		itemStyle: {
			normal: {
				color: '#FF99C3',
				borderColor: '#fff',
				borderWidth: 1
			}
		},
		lineStyle: {
			normal: {
				color: '#FF99C3'
			}
		}
	}]
}

export default {
	name: 'LineChart',
	components: {
		'v-chart': ECharts
	},
	props: {
		data: {
			type: Array,
			default: function() {
				return []
			}
		},
		indicator: {
			type: Array,
			default: function() {
				return []
			}
		}
	},
	computed: {
		optionData() {
			// options.series[0].data = this.data
			// options.radar.indicator = this.indicator
			// console.log(options)
			return options
		}
	},
	data() {
		return {
			initOptions: {
        renderer: 'canvas'
      }
		}
	}
}
</script>

<style lang="less">
.echarts {
	width: 100%;
	height: 100%;
}
</style>

<style lang="less" scoped>

</style>