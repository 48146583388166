<template>
	<div>
		<div class="user-comment-container" v-if="isList">
			<div class="comment-box" v-for="(item, index) in comments" :key="item.content + index">
				<div class="geo-comment-content-card border-box">
					<div class="geo-comment-content-card-header flex">
						<div class="geo-comment-content-card-author flex">
							<div class="geo-comment-content-card-author-avatar" :style="{backgroundImage: `url('${item.avatar}')`}"></div>
							<div class="geo-comment-content-card-author-name geo-one-ellipsis">{{item.nickname}}</div>
						</div>
						<div class="geo-comment-content-card-stars flex">
							<van-icon v-for="star in [1, 2, 3, 4, 5]" :name="(star <= item.star) ? 'star' : 'star-o'" :key="star" />
						</div>
					</div>
					<div class="geo-comment-content-card-detail">{{item.content}}</div>
				</div>
				<div class="geo-line"></div>
				<div class="tool-bar-box flex">
					<div class="date-box">{{item.create_time}}</div>
					<div class="tool-bar-btn-box">
						<van-button plain round @click="showConfirm(item.id, index)">删除</van-button>
					</div>
				</div>
			</div>
		</div>
		<div class="geo-empty-container" v-if="isEmpty">
			<van-empty
				class="custom-image"
				:image="require('../assets/empty_default.png')"
				description="暂无数据" />
		</div>
	</div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { getCommentHistory, rmComment } from '../api/User'
export default {
	name: '',
	data() {
		return {
			isList: false,
			isEmpty: false,
			page: 1,
			per_page: 20,
			comments: []
		}
	},
	async created() {
		const {code, data} = await getCommentHistory()
		if (code === 0) {
			this.isList = !!data.length
			this.isEmpty = !data.length
			this.comments = data
		} else if (code === 404) {
			this.isList = false
			this.isEmpty = true
		}
	},
	methods: {
		showConfirm(uid, index) {
			Dialog.confirm({
				title: '温馨提示',
				message: `确定要删除评论吗?`
			}).then(() => {
				this.remove(uid, index)
			})
		},
		async remove(uid, index) {
			const {code, data, msg} = await rmComment({}, uid)
			this.comments.splice(index, 1)
			this.isList = !!this.comments.length
			this.isEmpty = !this.comments.length
			Toast.success('删除成功')
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.user-comment-container {
	min-height: 100vh;
	background-color: @bgcolor;
	.comment-box {
		background: white;
		padding: 0.16rem;
		.tool-bar-box {
			font-size: 0.14rem;
			color: #999;
			padding-top: 0.16rem;
			button {
				height: 0.32rem;
				color: @red;
				border-color: @red;
			}
		}
	}
	.comment-box + .comment-box {
		margin-top: 8px;
	}
}
</style>