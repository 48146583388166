<template>
	<v-chart :options="optionData" :init-options="initOptions"></v-chart>
</template>

<script>
import ECharts from 'vue-echarts'
import * as echarts from 'echarts/dist/echarts.js'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'

export default {
	name: 'CircleBase',
	components: {
		'v-chart': ECharts
	},
	props: {
		data: {
			type: Array,
			default: function() {
				return []
			}
		},
		colors: {
			type: Array,
			default: function() {
				return []
			}
		}
	},
	computed: {
		optionData() {
			this.options.legend.data = this.data.map(item => item.type)
			this.options.series[0].data = this.data
			this.options.series[0].color = this.colors
			return this.options
		}
	},
	data() {
		return {
			initOptions: {
        renderer: 'canvas'
      },
			circleData: {},
			options: {
				animation : false,
				legend: {
					icon: 'circle',
					orient: 'horizontal',
					left: 0,
					data: [],
					textStyle: {
						fontSize: 12,
						color: '#8C8C8C'
					},
					itemWidth: 9,
					itemHeight: 9
				},
				series: [
						{
							name: '',
							type: 'pie',
							radius: ['30%', '71%'],
							avoidLabelOverlap: false,
							hoverAnimation: false,
							label: {
								show: false,
								position: 'center'
							},
							labelLine: {
								show: false
							},
							color: [],
							data: []
						}
				]
			}
		}
	}
}
</script>

<style lang="less">
.echarts {
	width: 100%;
	height: 100%;
}
</style>

<style lang="less" scoped>

</style>