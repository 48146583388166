<template>
	<div class="geo-name-card flex">
		<div class="left-box">
			<div class="left flex" :class="{'flex-justify-start': sex || birth}">
				<div class="geo-name-font-box" v-for="(font, index) in data.text" :key="font.v + index">
					<div class="geo-name-font geo-name-font-red">{{font.v}}</div>
					<div v-if="wuxing" class="geo-name-wuxing" :class="$wuxingmap[font.s]">{{font.s}}</div>
				</div>
			</div>
			<div class="left-item left-sex left-item-first" v-if="sex">性别：{{sex === 1 ? '男' : '女'}}</div>
			<div class="left-item left-sex" v-if="birth">出生时间：{{birth}}</div>
		</div>
		<div class="right">
			<div class="score">
				<h1>{{score !== undefined ? score : data.score}}</h1>
				<small>分</small>
			</div>
			<div class="intro">综合评分</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NameCard',
	props: {
		data: Object,
		wuxing: {
			type: Boolean,
			default: true
		},
		sex: {
			default: false
		},
		birth: {
			default: false
		},
		score: Number
	},
	data() {
		return {
			
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.geo-name-card {
	padding-bottom: 0.16rem;
}
.left-item {
	font-size: 0.14;
	color: #323232;
	margin-top: 8px;
}
.left-item-first {
	margin-top: 0.16rem;
}
.geo-name-card .right {
	color: @red;
	text-align: center;
	.score {
		h1 {
			display: inline-block;
			line-height: 0.38rem;
			font-size: 0.38rem;
		}
		small {
			font-size: 0.14rem;
			font-weight: @fw500;
			line-height: @lh20;
			margin-left: 4px;
		}
	}
	.intro {
		background: rgba(255, 85, 85, .1);
		border-radius: 10px;
		padding: 4px 8px;
		box-sizing: border-box;
		font-size: 0.12rem;
		line-height: 1;
		margin-top: 8px;
	}
}
</style>