<template>
	<div class="geo-score-card flex">
		<div class="geo-score-card-ele flex" v-for="(item) in table" :key="item.name">
			<div class="circle">
				<CircleChart v-if="item && $scoremap[item.name]" :title="getTitle(item.score, item.name)" :score="Number(item.score)" :color="$scoremap[item.name].color" :tcolor="$scoremap[item.name].tcolor" :radius="31" :barWidth="2"></CircleChart>
			</div>
			<div v-if="item && $scoremap[item.name]" class="score-box">
				<div class="name">{{$scoremap[item.name].name}}</div>
				<div class="score">{{`${item.score}分`}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import CircleChart from './CircleChart'
export default {
	name: 'ScoreCard',
	props: ['table'],
	data() {
		return {
			data: [{
				type: '',
				value: 0
			}]
		}
	},
	components: {
		CircleChart
	},
	methods: {
		getTitle(score, key) {
			return {
				text: score + '%',
				textStyle: {
					color: this.$scoremap[key].color,
					fontSize: 12,
					lineHeight: 12,
				},
				// subtext: score + '%',
				// subtextStyle: {
				// 	color: '#EA7373',
				// 	fontSize: 16,
				// 	lineHeight: 22
				// },
				itemGap: 4, // 主副标题距离
				left: 'center',
				top: 'center'
			}
		},
		eachViewBox(item, key) {
			let score = item === '?' ? 70 : item
			let thecolor = this.$scoremap[key].color
			return function(view, facet) {
				let data = facet.data;
				let color = thecolor;
				data[0].value = score
				data.push({
					type: '其他',
					value: 100 - data[0].value
				});
				view.source(data);
				view.coord('theta', {
					radius: 1,
					innerRadius: 0.8
				});
				view.intervalStack().position('value').color('type', [color, '#eceef1']).opacity(1);
			}
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.geo-score-card {
	flex-flow: row wrap;
	padding: 0.16rem 0;
	margin: 0 -0.06rem;
	margin-bottom: -0.2rem;
}
.geo-score-card-ele:not(:nth-child(3n)) {
	margin-right: 8px;
}
.geo-score-card-ele {
	width: calc(33.333333333% - 6px);
	flex-shrink: 0;
	line-height: @lh20;
	margin-bottom: 0.2rem;
	.score {
		color: @black;
	}
	.name {
		color: @black666;
	}
	.circle {
		width: 36px;
		height: 36px;
		margin-right: 8px;
		overflow: hidden;
	}
}
</style>