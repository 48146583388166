<template>
	<div class="geo-card" :class="['geo-card-' + theme, !title && 'no-title']">
		<div v-if="title" class="geo-card-header" :class="['geo-card-header-' + theme, collectedTitle && 'active']" @click.stop="showConfirm(nameId)">{{collectedTitle || title}}</div>
		<slot></slot>
	</div>
</template>

<script>
import { collectName, cancelCollectName, collectScore, cancelCollectScore } from '../api/User'
import { Toast, Dialog } from 'vant'
export default {
	name: 'card',
	props: ['title', 'theme', 'nameId', 'orderIndex', 'isScore', 'scoreData'],
	data() {
		return {
			isLogin: false,
			collectedTitle: ''
		}
	},
	methods: {
		showConfirm (id) {
			console.log(this.isScore, id)
			if (this.isScore && this.theme === 'collect') {
				this.collectScore()
				return
			}
			if (this.isScore && this.theme === 'cancelcollect' && id !== undefined) {
				this.uncollectScore(id)
				return
			}
			if (this.theme === 'collect' && id !== undefined) {
				Dialog.confirm({
					title: '温馨提示',
					message: `确定要收藏该名字吗?`
				}).then(() => {
					this.collectName(id)
				})
			} else if (this.theme === 'cancelcollect' && id !== undefined) {
				Dialog.confirm({
					title: '温馨提示',
					message: `确定要取消收藏该名字吗?`
				}).then(() => {
					this.uncollectName(id)
				})
			}
		},
		async collectScore() {
			const {msg, data, code} = await collectScore({}, this.scoreData.id)
			if (code === 0) {
				this.collectedTitle = '已收藏'
				Toast.success(msg)
			}
		},
		async collectName(id) {
			if (this.theme === 'collect') {
				const {msg, data, code} = await collectName({
					data: {
						'name_id': id
					}
				})
				if (code === 0) {
					this.collectedTitle = '已收藏'
					Toast.success(msg)
				}
			}
		},
		async uncollectName(id) {
			if (this.theme === 'cancelcollect') {
				const {msg, data, code} = await cancelCollectName({
					data: {
						'name_id': id
					}
				})
				if (code === 0) {
					Toast.success(msg)
					this.$emit('cancelSuccess', this.orderIndex)
				}
			}
		},
		async uncollectScore(id) {
			const {msg, data, code} = await cancelCollectScore({}, id)
			if (code === 0) {
				Toast.success(msg)
				this.$emit('cancelSuccess', this.orderIndex)
			}
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.geo-card {
	padding-left: 0.16rem;
	padding-right: 0.16rem;
	padding-bottom: 0.16rem;
	padding-top: 0.3rem;
	line-height: 0.2rem;
	font-size: 0.14rem;
	color: @black666;
	position: relative;
	background-color: #FFFFFF;
	box-shadow: 0px 0px 16px 0px rgba(230, 230, 230, 0.6);
	border-radius: 8px;
	box-sizing: border-box;
}
.geo-card.no-title {
	padding-top: 0.16rem;
}
.geo-card + .geo-card {
	margin-top: 0.3rem;
}
.geo-card-header {
	z-index: 1;
}
.geo-card-collect {
	border: 2px solid @red;
}
.geo-card-header-center {
	position: absolute;
	top: -0.14rem;
	left: 50%;
	background: @red;
	color: white;
	text-align: center;
	font-size: 0.16rem;
	line-height: 0.28rem;
	height: 0.28rem;
	transform: translate(-50%, 0);
	border-radius: 6px;
	padding: 0 8px;
	margin: auto;
}
.geo-card-header-collect {
	width: 0.54rem;
	height: 0.33rem;
	background-image: url('../assets/icon_collect.png');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	color: @red;
	text-align: center;
	font-size: 0.12rem;
	line-height: 0.17rem;
	padding-top: 5px;
	box-sizing: border-box;
	position: absolute;
	right: 8px;
	top: -0.06rem;
}
.geo-card-header-collect.active {
	color: white;
	background-image: url('../assets/icon_collect_active.png');
}
.geo-card-header-cancelcollect {
	width: 0.74rem;
	height: 0.33rem;
	background-image: url('../assets/icon_cancel_collection.png');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	color: white;
	text-align: center;
	font-size: 0.12rem;
	line-height: 0.17rem;
	padding-top: 5px;
	box-sizing: border-box;
	position: absolute;
	right: 8px;
	top: -0.06rem;
}
</style>