<template>
	<div class="lucky-history-container">
		<div v-for="item in 20" :key="item" class="lucky-history-outer">
			<div class="bg-box">
				<img class="bg1" src="../assets/lucky_card_bg1.png" alt="">
				<img class="bg2" src="../assets/lucky_card_bg2.png" alt="">
			</div>
			<div class="lucky-history-card">
				<div class="lucky-history-date">2020年12月9日 周三</div>
				<div class="lucky-history-content">吉穿：穿白色，银白色上衣，戌为土，土生金，金为白色，大环境生你的意思，办事易成，开心轻松……</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LuckyHistory',
	data() {
		return {
			
		}
	}
}
</script>

<style lang="less" scoped>
@import '../styles/var.less';
.lucky-history-container {
	min-height: 100vh;
	padding: 0.22rem 0.16rem;
	background: #850D09;
	.lucky-history-outer {
		position: relative;
		.bg-box {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			.bg1 {
				position: absolute;
				width: 0.68rem;
				height: 0.14rem;
				left: 2px;
				top: 9px;
			}
			.bg2 {
				position: absolute;
				width: 1.21rem;
				height: 1.06rem;
				right: 0;
				bottom: 0;
			}
		}
	}
	.lucky-history-card {
		background: white;
		border-radius: 8px;
		box-sizing: border-box;
		padding: 0.16rem;
		.lucky-history-date {
			line-height: 0.2rem;
			color: @black;
			font-weight: 500;
			margin-bottom: 8px;
		}
		.lucky-history-content {
			color: #666;
		}
	}
	.lucky-history-outer + .lucky-history-outer {
		margin-top: 0.16rem;
	}
}
</style>