export const isWx = () => {
	return /micromessenger/i.test(navigator.userAgent)
}

export function getDayTimestamp(date) {
	let year = date.getFullYear()
	let month = date.getMonth() + 1
	let day = date.getDate()

	return new Date(`${year}/${month}/${day}`).getTime()
}

export function getDateString(date, sp = '') {
	let year = date.getFullYear()
	let month = date.getMonth() + 1
	let day = date.getDate()

	month = month > 9 ? month : '0' + month.toString()
	day = day > 9 ? day : '0' + day.toString()

	return year.toString() + sp + month.toString() + sp + day.toString()
}

export function formatNumberWith (n) {
	return n > 9 ? n : '0' + n.toString()
}

export function isChn (str) {
	const reg = /^[\u4e00-\u9fa5]+$/
	return !!reg.test(str)
}

export function dateFormater (type, val) {
	if (type === 'year') {
		return val + '年';
	}
	if (type === 'month') {
		return val + '月';
	}
	if (type === 'day') {
		return val + '日';
	}
	if (type === 'hour') {
		return val + '时';
	}
	if (type === 'minute') {
		return val + '分';
	}
	return val;
}

export function formatBirthLabel (status) {
	if (!status || status === '1' || status === '已出生') {
		return '出生时间'
	}
	return '预产时间'
}


export const luckyName = 'GEO_LUCKY_DAILY'